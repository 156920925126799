import {Box, Typography} from '@mui/material';
import {ThreadsOverviewHeaderProps} from '../../../../foreground/utility/custComponentProps';

export default function Header({threads}: ThreadsOverviewHeaderProps) {
  const unreplied: number = threads
    .map(t =>
      t.thread.latestMessage.message.senderRefName.startsWith(
        'participantRecords/'
      )
        ? 1
        : 0
    )
    .reduce((a, b) => a + b, 0);
  const noThread: boolean = threads.length === 0;
  return (
    <Box sx={{padding: '0px 23px'}}>
      <Typography
        data-testid="overview-page-title"
        variant="body1"
        marginBottom="4px"
        component="h2"
        display="inline-block"
      >
        <strong>Messages</strong>
      </Typography>
      {noThread ? (
        <Typography
          data-testid="no-thread-message"
          variant="body2"
          color="text.muted"
        >
          0 messages. Click the pencil icon to start a new conversation.
        </Typography>
      ) : (
        <Typography variant="body2" color="text.muted">
          {unreplied === 0 ? 'No' : unreplied} unreplied
        </Typography>
      )}
    </Box>
  );
}
