/** Constants used by VHub clients. */

// MFE access gated nav path constants.
export const EnterprisePrefix = '/enterprise/';
export type EnterprisePrefixed = `${typeof EnterprisePrefix}${string}`;
export const ORG_ID_TEMPLATE_STRING = 'organizations/:orgId';
export type HasOrgId = `${string}/${typeof ORG_ID_TEMPLATE_STRING}/${string}`;

// To be removed, these are not access gated nav paths, see https://verily.atlassian.net/browse/ONEVERILY-28347.
export const DETAIL_VIEW_RESEARCH: EnterprisePrefixed = `${EnterprisePrefix}provider/participants/detail`;
export const DETAIL_VIEW_CARE: EnterprisePrefixed = `${EnterprisePrefix}provider/patients/detail`;

export const ORGANIZATIONS_PATH: EnterprisePrefixed = `${EnterprisePrefix}organizations/list`;
export const USERS_PATH: EnterprisePrefixed = `${EnterprisePrefix}users/list`;
export const ACCOUNTS_PATH: EnterprisePrefixed = `${EnterprisePrefix}care-accounts/list`;
export const STUDIES_PATH: EnterprisePrefixed & HasOrgId =
  `${EnterprisePrefix}builder/${ORG_ID_TEMPLATE_STRING}/my-studies` as const;
export const CARE_PROGRAMS_PATH: EnterprisePrefixed & HasOrgId =
  `${EnterprisePrefix}builder/${ORG_ID_TEMPLATE_STRING}/my-care-programs` as const;
export const CARE_PATHWAYS_PATH: EnterprisePrefixed & HasOrgId =
  `${EnterprisePrefix}builder/${ORG_ID_TEMPLATE_STRING}/my-care-pathways` as const;
export const CONTENT_LIBRARY_PATH: EnterprisePrefixed = `${EnterprisePrefix}content`;
export const DASHBOARDS_PATH: EnterprisePrefixed = `${EnterprisePrefix}analysis`;
export const LIST_VIEW_CARE: EnterprisePrefixed = `${EnterprisePrefix}provider/patients/list`;
export const LIST_VIEW_RESEARCH: EnterprisePrefixed = `${EnterprisePrefix}provider/participants/list`;
export const PROVENANCE_VISUALIZATION_PATH: EnterprisePrefixed = `${EnterprisePrefix}provenance/visualization`;
export const TEST_ACCOUNT_MANAGER_PATH: EnterprisePrefixed = `${EnterprisePrefix}test-account-manager/projects`;
export const WORKFLOW_VISUALIZATION_PATH: EnterprisePrefixed = `${EnterprisePrefix}workflow-visualization-tool`;
export const WORKFLOWS_CATALOGUE_PATH: EnterprisePrefixed = `${EnterprisePrefix}workflows-catalogue`;
export const PROTOCOL_DIGITIZER_PATH: EnterprisePrefixed = `${EnterprisePrefix}protocol-digitizer`;
export const FHIR_EDITOR_PATH: EnterprisePrefixed = `${EnterprisePrefix}builder/fhir-editor`;
export const FHIR_MAPPING_PATH: EnterprisePrefixed = `${EnterprisePrefix}ingestion/fhirmapping`;
export const CORTEX_BROWSER_PATH: EnterprisePrefixed = `${EnterprisePrefix}cortex/browser`;
export const INGESTION_PIPELINES_PATH: EnterprisePrefixed = `${EnterprisePrefix}ingestion/pipelines`;
// Vhub non access gated paths.
export const PROFILE_PATH: EnterprisePrefixed = `${EnterprisePrefix}profile`;
export const ERROR_PATH: EnterprisePrefixed = `${EnterprisePrefix}error`;
export const PERMISSION_ERROR_PATH: EnterprisePrefixed = `${EnterprisePrefix}permission-error`;
export const HOME_PATH: EnterprisePrefixed = `${EnterprisePrefix}home`;

// See: go/nav-paths-explained, this needs to be kept in sync with
// -  vhub/bff/internal/applicationstate/service.go
// -  professional/role/lib/data/role_permission.textproto
// Comment the text of the nav item on the right to make this easier to diff by eye.
export const ACCESS_GATED_NAV_PATHS = new Set<EnterprisePrefixed>([
  // ** SPS Paths. **

  // Vhub / Enterprise portal paths.
  TEST_ACCOUNT_MANAGER_PATH,

  // Builder paths.
  CARE_PATHWAYS_PATH, // Care pathways
  CARE_PROGRAMS_PATH, // Care programs
  STUDIES_PATH, // Studies
  FHIR_EDITOR_PATH, // FHIR Editor

  // VCMS path.
  CONTENT_LIBRARY_PATH, // "Content studio"

  // Participant Ops console paths.
  LIST_VIEW_CARE, // "Patients List & Detail"
  LIST_VIEW_RESEARCH, // "Participant List & Detail"

  // Organization & Professional paths.
  ACCOUNTS_PATH, // "Care accounts"
  ORGANIZATIONS_PATH, // "Organizations"
  USERS_PATH, // "Users"

  // ** PHP Paths. **

  // Looker Analysis path.
  DASHBOARDS_PATH, // "Dashboards"

  // Visualization path.
  PROVENANCE_VISUALIZATION_PATH, // "Provenance Visualization"

  // Protocol Digitizer path
  PROTOCOL_DIGITIZER_PATH, // "Protocol Digitization"

  // ** The below paths should be removed once they are properly access-gated **
  // TODO(ONEVERILY-51852)
  // Workflow Visualization path
  WORKFLOW_VISUALIZATION_PATH, // "Workflow Visualization"
  // Workflows Catalogue path
  WORKFLOWS_CATALOGUE_PATH, // "Workflows Catalogue"

  // TODO(ONEVERILY-51987)
  // FHIR Mapping path
  FHIR_MAPPING_PATH, // "FHIR Mapping explorer"

  // Cortex Browser path
  CORTEX_BROWSER_PATH, // "Cortex Browser"

  // Ingestion Pipelines path
  INGESTION_PIPELINES_PATH, // "Ingestion Pipelines"
]);

export const CURRENT_ORG_KEY = 'current_org';

// TODO(ONEVERILY-44868): Remove this key once the org switcher is launched.
export const FORCED_ORG_KEY = 'force_org';
