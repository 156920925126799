// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinnerOverlay__CdyjZ{align-items:center;display:flex;height:80px;justify-content:center;position:absolute;top:45%;width:80px;z-index:2}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyme/components/LoadingCircularProgress/LoadingCircularProgress.css"],"names":[],"mappings":"AAAA,uBASE,kBAAmB,CADnB,YAAa,CAJb,WAAY,CAMZ,sBAAuB,CATvB,iBAAkB,CAClB,OAAQ,CACR,UAAW,CAEX,SAMF","sourcesContent":[".spinnerOverlay {\n  position: absolute;\n  top: 45%;\n  width: 80px;\n  height: 80px;\n  z-index: 2;\n\n  /* center the spinner in the overlay */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerOverlay": `spinnerOverlay__CdyjZ`
};
export default ___CSS_LOADER_EXPORT___;
