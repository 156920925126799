// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinnerOverlay__Hpbn7{align-items:center;display:flex;justify-content:center;left:46%;position:absolute;top:50%;z-index:2}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyenterprise/components/LoadingCircularProgress/LoadingCircularProgress.css"],"names":[],"mappings":"AAAA,uBAQE,kBAAmB,CADnB,YAAa,CAEb,sBAAuB,CANvB,QAAS,CAFT,iBAAkB,CAClB,OAAQ,CAER,SAMF","sourcesContent":[".spinnerOverlay {\n  position: absolute;\n  top: 50%;\n  left: 46%;\n  z-index: 2;\n\n  /* center the spinner in the overlay */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerOverlay": `spinnerOverlay__Hpbn7`
};
export default ___CSS_LOADER_EXPORT___;
