// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageContainer__i96Fv{-ms-overflow-style:none;align-items:stretch;display:flex;flex:1 1 0;flex-direction:column-reverse;justify-content:space-between;overflow:auto;scrollbar-width:none}.MessageContainer__i96Fv::-webkit-scrollbar{display:none}.MessagesAndSpinner__tUlXC{align-items:center;display:flex;justify-content:center}.MessageList__K5GhA{align-items:stretch;display:flex;flex-direction:column-reverse;justify-content:flex-start;width:100%}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyme/components/ThreadDetail/MessageContainer.css"],"names":[],"mappings":"AAAA,yBAOE,uBAAwB,CAHxB,mBAAoB,CADpB,YAAa,CAFb,UAAW,CAKX,6BAA8B,CAD9B,6BAA8B,CAH9B,aAAc,CAMd,oBACF,CAGA,4CACE,YACF,CAEA,2BAEE,kBAAmB,CADnB,YAAa,CAEb,sBACF,CAEA,oBAGE,mBAAoB,CAFpB,YAAa,CAIb,6BAA8B,CAD9B,0BAA2B,CAF3B,UAIF","sourcesContent":[".MessageContainer {\n  flex: 1 1 0;\n  overflow: auto;\n  display: flex;\n  align-items: stretch;\n  justify-content: space-between;\n  flex-direction: column-reverse;\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.MessageContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.MessagesAndSpinner {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.MessageList {\n  display: flex;\n  width: 100%;\n  align-items: stretch;\n  justify-content: flex-start;\n  flex-direction: column-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MessageContainer": `MessageContainer__i96Fv`,
	"MessagesAndSpinner": `MessagesAndSpinner__tUlXC`,
	"MessageList": `MessageList__K5GhA`
};
export default ___CSS_LOADER_EXPORT___;
