import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  AddCircleIcon,
  Button,
  PhotoIcon,
  TextField,
} from '@verily-src/react-design-system';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AttachmentInput from '../../../../foreground/components/AttachmentInput/AttachmentInput';
import AttachmentPreviewWithClose from '../../../../foreground/components/AttachmentPreview/AttachmentPreviewWithClose';
import {CreateThreadSendBoxProps} from '../../../../foreground/utility/custComponentProps';
import {breakpointValues} from '../../styles/breakpoints';
import {ORGANIZATION_NAME, VerilyLogo} from '../Common/VerilyLogo';
import styles from './SendBox.css';

export const AttachmentCreater = props => (
  <AttachmentInput
    buttonProps={{
      id: 'messaging_createthread_attachment',
      sx: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        minHeight: '56px',
        padding: '4px 16px',
        borderRadius: '999px',
        justifyContent: 'flex-start',
        border: 1,
        borderColor: 'background.separator',
        '&:hover': {backgroundColor: 'background.separator'},
      },
    }}
    attachIconHandler={props.attachIconHandler}
  >
    {
      // TODO: Delete feature flag after merged to production
      config.getBoolean('FEATURE_PDF_ATTACHMENT_ENABLED') ? (
        <AddCircleIcon color="action" />
      ) : (
        <PhotoIcon color="action" />
      )
    }
    <Typography variant="body1" style={{padding: '12px 0'}}>
      {props.text}
    </Typography>
  </AttachmentInput>
);

export default function SendBox(props: CreateThreadSendBoxProps) {
  const {t} = useTranslation();
  const isDesktop = useMediaQuery(`(min-width:${breakpointValues.md}px)`);
  const [organization, setOrganization] = useState<string>('');
  const theme = useTheme();
  useEffect(() => {
    // TODO: call BFF after BFF/BE API is implemented.
    setOrganization(ORGANIZATION_NAME);
  }, []);
  const attachmentPreview = props.fileAttachment
    ? {
        endAdornment: (
          <AttachmentPreviewWithClose
            mimeType={props.fileAttachment.type}
            url={props.fileAttachment.url}
            dimension={160}
            sxExtra={{alignSelf: 'flex-start'}}
            onClickHandler={props.closeAttachHandler}
            meta={props.fileAttachment.meta}
          />
        ),
      }
    : {};
  const sendButtonSx = isDesktop ? {width: '224px', alignSelf: 'flex-end'} : {};
  const titleLabelId = 'messaging_createthread_title_label';
  return (
    <div className={styles.SendBox}>
      <Box
        sx={{
          padding: '8px 0px',
          display: 'flex',
          alignItems: 'center',
          margin: '0px 0px 8px 0px',
          gap: '8px',
        }}
      >
        <VerilyLogo />
        <Typography variant="display6" component="h2">
          {organization}
        </Typography>
      </Box>
      <TextField
        id="messaging_createthread_title"
        placeholder={t('threadPlaceHolder_createThread_verilyMe')}
        autoFocus={true}
        fullWidth={true}
        onChange={props.threadBoxHandler}
        inputProps={{maxLength: 100}}
      />
      <span
        style={{
          paddingLeft: '16px',
          marginTop: '-14px',
          fontSize: '12px',
          color: theme.palette.text.muted,
        }}
      >
        {`${props.conversationName?.length}/100 ${t(
          'characters_createThread_verilyMe'
        )}`}
      </span>
      <TextField
        id={props.textFieldId}
        placeholder={t('messagePlaceHolder_createThread_verilyMe')}
        fullWidth={true}
        sx={{
          '.MuiOutlinedInput-root': {
            flexDirection: 'column',
            gap: '10px',
          },
        }}
        onChange={props.messageBoxHandler}
        multiline
        rows={props.fileAttachment ? 8 : 12}
        InputProps={{style: {padding: '0px 16px'}, ...attachmentPreview}}
      />
      {!props.fileAttachment && (
        <AttachmentCreater
          attachIconHandler={props.attachIconHandler}
          text={
            // TODO: Delete feature flag after merged to production
            config.getBoolean('FEATURE_PDF_ATTACHMENT_ENABLED')
              ? t('attachBotton_createThread_file')
              : t('attachBotton_createThread_verilyMe')
          }
        />
      )}
      <div className={styles.Warning}>
        <Typography
          variant="body2"
          color="text.muted"
          sx={{padding: '8px 50px'}}
        >
          {t('warning_common_verilyMe')}
        </Typography>
      </div>
      <Button
        id="messaging_createthread_send"
        color="primary"
        variant="filled"
        disabled={
          (!props.messageContent && !props.fileAttachment) ||
          !props.conversationName ||
          props.buttonLoading
        }
        aria-label={t('ariaSendMessage_common_common')}
        title={t('ariaSendMessage_common_common')}
        onClick={props.sendIconHandler}
        data-testid="send-icon"
        sx={{
          margin: '32px 0px',
          padding: '16px 24px',
          ...sendButtonSx,
        }}
      >
        {t('send_createThread_verilyMe')}
      </Button>
      <div hidden>
        <label id={titleLabelId} htmlFor="messaging_createthread_title">
          {t('threadPlaceHolder_createThread_verilyMe')}
        </label>
      </div>
    </div>
  );
}
