import {useMediaQuery} from '@mui/material';
import {Auth, LoginStatus} from '@verily-src/auth';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {
  ConsumerLightTheme,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api/src/verily-verily-me-api';
import {ApplicationSuite} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {Subscription} from 'rxjs';
import createBFFClient from '../api/createBFFClient';
import {getHyperlinkPreviewAllowList} from '../api/getHyperlinkPreviewAllowList';
import {getMessagingConfiguration} from '../api/getMessagingConfiguration';
import NavUpdater from '../background/components/NavUpdater/NavUpdater';
import ThreadsListener from '../background/components/ThreadsListener/ThreadsListener';
import verilyMeCusts from '../customizations/verilyme';
import {breakpointValues} from '../customizations/verilyme/styles/breakpoints';
import {ThemeContextProvider} from '../foreground/components/ThemeContext/ThemeContext';
import Messaging from '../foreground/Messaging';

export default function AppMe() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(`(min-width:${breakpointValues.md}px)`);
  const isLargeScreen = useMediaQuery(`(min-width:${breakpointValues.lg}px)`);
  const client = createBFFClient();
  const [hyperlinkPreviewAllowList, setHyperlinkPreviewAllowList] = useState<
    string[]
  >([]);
  const hasFetchedConfiguration = useRef(false);
  const [isMessagingEnabled, setIsMessagingEnabled] = useState<boolean>(false);
  const [participantRecordRefNames, setParticipantRecordRefNames] = useState<
    string[]
  >([]);
  if (isDesktop) {
    verilyMeCusts.styles.Common.maxWidth = isLargeScreen ? '1136px' : '636px';
    verilyMeCusts.styles.Common.minHeight = '100vh';
    verilyMeCusts.styles.Common.padding = '24px 32px';
  } else {
    verilyMeCusts.styles.Common.maxWidth = 'auto';
    verilyMeCusts.styles.Common.minHeight = 'calc(100vh - 74px)';
    verilyMeCusts.styles.Common.padding = '16px';
  }

  useEffect(() => {
    const sub: Subscription = Auth.loginStatus$.subscribe(
      (loginStatus: LoginStatus) => {
        if (
          window.location.href.includes('me/messaging') &&
          loginStatus !== LoginStatus.LoggedIn
        ) {
          navigate('/me/home');
        } else if (
          config.getBoolean('FEATURE_MESSAGING_CONFIGURABILITY_ENABLED')
        ) {
          if (!hasFetchedConfiguration.current) {
            hasFetchedConfiguration.current = true;
            getMessagingConfiguration(client, ApplicationSuite.VERILY_ME)
              .then(r => {
                setIsMessagingEnabled(r.isMessagingEnabled);
                setParticipantRecordRefNames(
                  r.enabledParticipantRecords.map(rec => {
                    return rec.participantRecordRefName;
                  })
                );
              })
              .catch(err => {
                console.log(
                  'Error: failed to get messaging configuration, ',
                  err
                );
              });
          }
        } else {
          setIsMessagingEnabled(true);
        }
      }
    );
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      config.getBoolean('FEATURE_HYPERLINK_PREVIEW_ENABLED') &&
      isMessagingEnabled
    ) {
      const hyperlinkAllowList = async () => {
        try {
          const allowListReceived = (await getHyperlinkPreviewAllowList(client))
            .hostnames;
          setHyperlinkPreviewAllowList(allowListReceived);
        } catch (err) {
          console.log(
            'Error: failed to get hyperlink preview allow list, ',
            err
          );
        }
      };
      hyperlinkAllowList();
    }
  }, [isMessagingEnabled]);

  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  const meContext = {
    ...verilyMeCusts,
    theme: {...theme, locale},
    applicationSuiteData: {
      participantRecordRefName: participantRecordRefNames[0],
    },
    hyperlinkPreviewAllowList: hyperlinkPreviewAllowList,
  };
  return (
    isMessagingEnabled && (
      <ThemeContextProvider value={meContext}>
        <ThreadsListener />
        <NavUpdater />
        <Routes>
          <Route
            path="/messaging"
            element={
              <Messaging
                pageTitle={`Verily Me | ${t(
                  'heading_threadsOverview_verilyMe'
                )}`}
              />
            }
          />
        </Routes>
      </ThemeContextProvider>
    )
  );
}
