import {Box, List, Typography, useMediaQuery} from '@mui/material';
import {Timestamp} from '@verily-src/verily1-protos/google/protobuf/timestamp';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ThreadDetailMessageContainerProps} from '../../../../foreground/utility/custComponentProps';
import {breakpointValues} from '../../styles/breakpoints';
import {minuteMs} from '../Common/timeConverter';
import {ORGANIZATION_NAME, VerilyLogo} from '../Common/VerilyLogo';
import LoadingCircularProgress from '../LoadingCircularProgress/LoadingCircularProgress';
import Message from './Message';
import styles from './MessageContainer.css';

function ThreadTitle({orgName, threadName}) {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <VerilyLogo />
        <Typography variant="body1" component="h2">
          {orgName}
        </Typography>
      </Box>
      <Typography
        variant="display5"
        sx={{wordBreak: 'break-word', fontSize: '18px'}}
      >
        <strong>{threadName}</strong>
      </Typography>
    </Box>
  );
}

function WarningContent({text}) {
  return (
    <Typography
      variant="body2"
      sx={{textAlign: 'center', padding: '30px 50px'}}
      color="text.muted"
    >
      {text}
    </Typography>
  );
}

export default function MessageContainer(
  props: ThreadDetailMessageContainerProps
) {
  const isDesktop = useMediaQuery(`(min-width:${breakpointValues.md}px)`);
  const [messageCount, setMessageCount] = useState(0);
  const {t} = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const sentinelRef = useCallback((node: HTMLDivElement) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsScrolled(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 1.0,
      }
    );

    if (node) {
      setTimeout(() => {
        observer.observe(node);
      }, 100);
    }
  }, []);

  const warningRef = useRef(null);
  const scrollToBottom = () => {
    warningRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  useEffect(() => {
    if (messageCount !== props.messages.length) {
      setMessageCount(props.messages.length);
      scrollToBottom();
    }
  }, [props.messages.length]);

  const boxSx = isDesktop
    ? {
        padding: '12px 32px',
        borderTop: 1,
        borderLeft: 1,
        borderRight: 1,
        borderRadius: '16px 16px 0px 0px',
        borderColor: 'background.separator',
      }
    : {
        borderTop: isScrolled ? 0.5 : 0,
        borderColor: 'background.separator',
        padding: '12px 16px',
        margin: '0px -16px',
      };

  const isSameSenderWithPrevMessage = (index: number): boolean => {
    if (index === props.messages.length - 1) return false;
    return (
      props.messages[index].senderName === props.messages[index + 1].senderName
    );
  };

  const isSameSenderWithNextMessage = (index: number): boolean => {
    if (index === 0) return false;
    return (
      props.messages[index].senderName === props.messages[index - 1].senderName
    );
  };

  const isLessThenOneMinuteIntervalWithNextMessage = (
    index: number
  ): boolean => {
    if (index === 0) return false;
    const thisSentTime = Timestamp.toDate(
      props.messages[index].message.sentTime
    ).getTime();
    const prevSentTime = Timestamp.toDate(
      props.messages[index - 1].message.sentTime
    ).getTime();
    return -(thisSentTime - prevSentTime) < minuteMs;
  };

  return (
    <Box className={styles.MessageContainer} sx={boxSx}>
      <div ref={warningRef}>
        <WarningContent text={t('warning_common_verilyMe')} />
      </div>
      <div className={styles.MessagesAndSpinner}>
        {props.messages.length > 0 && (
          <List className={styles.MessageList} role="none">
            {props.messages.map((m, index: number) => (
              <Message
                key={m.message.messageRefName}
                messageWrap={{...m}}
                showSender={!isSameSenderWithPrevMessage(index)}
                showTimeSince={
                  !isSameSenderWithNextMessage(index) ||
                  !isLessThenOneMinuteIntervalWithNextMessage(index)
                }
              />
            ))}
          </List>
        )}
        <LoadingCircularProgress loading={props.loading} />
      </div>
      <div ref={sentinelRef}>
        {isDesktop || (
          <ThreadTitle
            threadName={props.threadName}
            orgName={ORGANIZATION_NAME}
          />
        )}
      </div>
    </Box>
  );
}
