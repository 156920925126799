import {Nav, NavItem} from '@verily-src/nav';
import {ThreadWrap} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {getThreads} from '../../utility/threadNotifier';

type NavItemType = {
  route: NavItem;
  unread: boolean;
};

const getNavItem = (
  unread: boolean,
  text: string,
  ariaLabel: string
): NavItemType => ({
  route: {
    name: text,
    path: '/me/messaging',
    order: 2,
    icon: unread
      ? 'UnreadMessageVerilyMeOutlinedIcon'
      : 'MessageVerilyMeOutlinedIcon',
    selectedIcon: unread
      ? 'UnreadMessageVerilyMeFilledIcon'
      : 'MessageVerilyMeFilledIcon',
    children: [],
    ariaLabel: ariaLabel,
  },
  unread: unread,
});

const countUnreadStatus = (threads: ThreadWrap[]): number => {
  // TODO: update the count of unread messages logic.
  const unreadCount = threads
    .map(t => (t.thread.latestMessage.message.isRead ? 0 : 1))
    .reduce((a, b) => a + b, 0);
  return unreadCount;
};

export default function NavUpdater() {
  const {t} = useTranslation();
  const navText = t('text_nav_verilyMe');
  useEffect(() => {
    if (Nav === undefined) {
      return;
    }
    let currentNav: NavItemType = getNavItem(false, navText, navText);
    Nav.addItem(currentNav.route);
    const sub = getThreads().subscribe({
      next: obs => {
        const unreadCount = countUnreadStatus(obs.threads);
        if (unreadCount > 0) {
          Nav.removeItem(currentNav.route);
          currentNav = getNavItem(
            true,
            navText,
            `${navText}, ${unreadCount} ${t('ariaUnreadMessages_nav')}`
          );
          Nav.addItem(currentNav.route);
        }
      },
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return null;
}
