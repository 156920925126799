import {Typography, useTheme} from '@mui/material';
import {Tag} from '@verily-src/react-design-system';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ThreadsOverviewThreadProps} from '../../../../foreground/utility/custComponentProps';
import {dateFormat, dateMedFormat} from '../Common/timeConverter';
import {ORGANIZATION_NAME, VerilyLogo} from '../Common/VerilyLogo';
import styles from './Thread.css';

export const getPreText = (fileType, t) => {
  if (fileType?.startsWith('image/') || fileType === '') {
    return `[${t('imageTag_threadsOverview_verilyMe')}] `;
  }
  return fileType ? `[${t('fileTag_threadsOverview_verilyMe')}] ` : '';
};

export default function Thread(props: ThreadsOverviewThreadProps) {
  const {t} = useTranslation();
  const focusRef = useCallback(inputElement => {
    if (props.autoFocus && inputElement) {
      inputElement.focus();
    }
  }, []);
  const [hover, setHover] = useState<boolean>(false);
  const [ariaLabel, setAriaLabel] = useState<string>(props.ariaLabel);
  const theme = useTheme();

  useEffect(() => {
    // Let the VoiceOver anounce all text in each message including 'Unread'
    const ariaLabelText = [`${ORGANIZATION_NAME}, `, props.ariaLabel];
    if (!props.thread.latestMessage.message.isRead) {
      ariaLabelText.push(t('unreadTag_threadsOverview_verilyMe'));
    }
    ariaLabelText.push(
      dateMedFormat(props.thread.latestMessage.message.sentTime)
    );
    ariaLabelText.push(props.thread.latestMessage.senderName);
    ariaLabelText.push(
      props.thread.latestMessage.message.messageData.messageText
    );
    setAriaLabel(ariaLabelText.join(', '));
  }, [props.thread]);

  // Select is default to false before wide display responsive UI is implemented.
  const select = false;
  const selectBkg = select
    ? theme.palette.primary.main
    : theme.palette.background.paper;
  const selectMuted = select ? 'text.contrast' : 'text.muted';
  const selectDefault = select ? 'text.contrast' : 'text.default';
  const preText = getPreText(
    props.thread.latestMessage.message.messageData?.attachment?.mimeType,
    t
  );
  return (
    <button
      id={props.thread.threadRefName}
      ref={focusRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display: 'flex',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        maxWidth: '572px',
        gap: '8px',
        borderRadius: '24px',
        borderWidth: 0.5,
        borderColor: theme.palette.background.separator,
        backgroundColor: hover ? theme.palette.state.hoverOnSurface : selectBkg,
      }}
      onClick={props.redirect}
      aria-label={ariaLabel}
    >
      <div className={styles.ThreadHeader}>
        <VerilyLogo />
        <Typography
          variant="body2"
          color={selectMuted}
          sx={{flex: '1', textAlign: 'left'}}
        >
          {ORGANIZATION_NAME}
        </Typography>
        {props.thread.latestMessage.message.isRead ? (
          <Typography
            data-testid="thread-date"
            variant="body2"
            color={selectMuted}
          >
            {dateFormat(props.thread.latestMessage.message.sentTime)}
          </Typography>
        ) : (
          <Tag
            label={t('unreadTag_threadsOverview_verilyMe')}
            color="primary"
            emphasis="high"
          />
        )}
      </div>
      <Typography
        data-testid="thread-title"
        variant="display6"
        color={selectDefault}
        sx={{
          overflow: 'hidden',
          height: '24px',
          wordBreak: 'break-all',
          fontSize: '18px',
        }}
      >
        <strong>{props.thread.title}</strong>
      </Typography>
      <Typography
        data-testid="thread-details"
        variant="body2"
        color={selectMuted}
        sx={{wordBreak: 'break-word', overflow: 'hidden', height: '20px'}}
      >
        {props.thread.latestMessage.senderName}: {preText}
        {props.thread.latestMessage.message.messageData.messageText}
      </Typography>
    </button>
  );
}
