/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "messaging/bff/api/messaging_bff_service.proto" (package "messaging.bff.api", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MessagingBFFService } from "./messaging_bff_service";
import type { MessagingConfiguration } from "./messaging_bff_service";
import type { GetMessagingConfigurationRequest } from "./messaging_bff_service";
import type { Empty } from "../../../google/protobuf/empty";
import type { SetThreadAsReadRequest } from "./messaging_bff_service";
import type { HyperlinkPreviewResource } from "./messaging_bff_service";
import type { GetHyperlinkPreviewResourceRequest } from "./messaging_bff_service";
import type { GetHyperlinkPreviewAllowListResponse } from "./messaging_bff_service";
import type { GetHyperlinkPreviewAllowListRequest } from "./messaging_bff_service";
import type { ParticipantRecord } from "./messaging_bff_service";
import type { GetParticipantRecordNameRequest } from "./messaging_bff_service";
import type { MessageAttachment } from "./messaging_bff_service";
import type { GetMessageAttachmentRequest } from "./messaging_bff_service";
import type { GetThreadRequest } from "./messaging_bff_service";
import type { ListThreadsResponse } from "./messaging_bff_service";
import type { ListThreadsRequest } from "./messaging_bff_service";
import type { ThreadWrap } from "./messaging_bff_service";
import type { CreateThreadRequest } from "./messaging_bff_service";
import type { ListMessagesResponse } from "./messaging_bff_service";
import type { ListMessagesRequest } from "./messaging_bff_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { MessageWrap } from "./messaging_bff_service";
import type { CreateMessageRequest } from "./messaging_bff_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * **
 * This MessagingService provides the APIs for the Messaging MFE of VerilyMe Web
 * and Verily Enterprise.
 *
 * @generated from protobuf service messaging.bff.api.MessagingBFFService
 */
export interface IMessagingBFFServiceClient {
    /**
     * Create a message under an existing thread
     *
     * @generated from protobuf rpc: CreateMessage(messaging.bff.api.CreateMessageRequest) returns (messaging.bff.api.MessageWrap);
     */
    createMessage(input: CreateMessageRequest, options?: RpcOptions): UnaryCall<CreateMessageRequest, MessageWrap>;
    /**
     * List the latest messages for a specific thread
     *
     * @generated from protobuf rpc: ListMessages(messaging.bff.api.ListMessagesRequest) returns (messaging.bff.api.ListMessagesResponse);
     */
    listMessages(input: ListMessagesRequest, options?: RpcOptions): UnaryCall<ListMessagesRequest, ListMessagesResponse>;
    /**
     * Create a new thread with the first message
     *
     * @generated from protobuf rpc: CreateThread(messaging.bff.api.CreateThreadRequest) returns (messaging.bff.api.ThreadWrap);
     */
    createThread(input: CreateThreadRequest, options?: RpcOptions): UnaryCall<CreateThreadRequest, ThreadWrap>;
    /**
     * List all threads with the latest message
     *
     * @generated from protobuf rpc: ListThreads(messaging.bff.api.ListThreadsRequest) returns (messaging.bff.api.ListThreadsResponse);
     */
    listThreads(input: ListThreadsRequest, options?: RpcOptions): UnaryCall<ListThreadsRequest, ListThreadsResponse>;
    /**
     * Get the information of a single thread
     *
     * @generated from protobuf rpc: GetThread(messaging.bff.api.GetThreadRequest) returns (messaging.bff.api.ThreadWrap);
     */
    getThread(input: GetThreadRequest, options?: RpcOptions): UnaryCall<GetThreadRequest, ThreadWrap>;
    /**
     * Get a message attachment
     *
     * @generated from protobuf rpc: GetMessageAttachment(messaging.bff.api.GetMessageAttachmentRequest) returns (messaging.bff.api.MessageAttachment);
     */
    getMessageAttachment(input: GetMessageAttachmentRequest, options?: RpcOptions): UnaryCall<GetMessageAttachmentRequest, MessageAttachment>;
    /**
     * Get the human name of patient
     * Should only be called by Verily Enterprise
     *
     * @generated from protobuf rpc: GetParticipantRecordName(messaging.bff.api.GetParticipantRecordNameRequest) returns (messaging.bff.api.ParticipantRecord);
     */
    getParticipantRecordName(input: GetParticipantRecordNameRequest, options?: RpcOptions): UnaryCall<GetParticipantRecordNameRequest, ParticipantRecord>;
    /**
     * Get a list of approved hostnames for hyperlink previews.
     * Can be called by authenticated users
     *
     * @generated from protobuf rpc: GetHyperlinkPreviewAllowList(messaging.bff.api.GetHyperlinkPreviewAllowListRequest) returns (messaging.bff.api.GetHyperlinkPreviewAllowListResponse);
     */
    getHyperlinkPreviewAllowList(input: GetHyperlinkPreviewAllowListRequest, options?: RpcOptions): UnaryCall<GetHyperlinkPreviewAllowListRequest, GetHyperlinkPreviewAllowListResponse>;
    /**
     * Get the resource needed for hyperlink preview.
     * Can be called by authenticated users
     *
     * @generated from protobuf rpc: GetHyperlinkPreviewResource(messaging.bff.api.GetHyperlinkPreviewResourceRequest) returns (messaging.bff.api.HyperlinkPreviewResource);
     */
    getHyperlinkPreviewResource(input: GetHyperlinkPreviewResourceRequest, options?: RpcOptions): UnaryCall<GetHyperlinkPreviewResourceRequest, HyperlinkPreviewResource>;
    /**
     * Add a reader for all messages of a thread
     *
     * @generated from protobuf rpc: SetThreadAsRead(messaging.bff.api.SetThreadAsReadRequest) returns (google.protobuf.Empty);
     */
    setThreadAsRead(input: SetThreadAsReadRequest, options?: RpcOptions): UnaryCall<SetThreadAsReadRequest, Empty>;
    /**
     * Get Messaging Configuration for participant user
     *
     * @generated from protobuf rpc: GetMessagingConfiguration(messaging.bff.api.GetMessagingConfigurationRequest) returns (messaging.bff.api.MessagingConfiguration);
     */
    getMessagingConfiguration(input: GetMessagingConfigurationRequest, options?: RpcOptions): UnaryCall<GetMessagingConfigurationRequest, MessagingConfiguration>;
}
/**
 * **
 * This MessagingService provides the APIs for the Messaging MFE of VerilyMe Web
 * and Verily Enterprise.
 *
 * @generated from protobuf service messaging.bff.api.MessagingBFFService
 */
export class MessagingBFFServiceClient implements IMessagingBFFServiceClient, ServiceInfo {
    typeName = MessagingBFFService.typeName;
    methods = MessagingBFFService.methods;
    options = MessagingBFFService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Create a message under an existing thread
     *
     * @generated from protobuf rpc: CreateMessage(messaging.bff.api.CreateMessageRequest) returns (messaging.bff.api.MessageWrap);
     */
    createMessage(input: CreateMessageRequest, options?: RpcOptions): UnaryCall<CreateMessageRequest, MessageWrap> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateMessageRequest, MessageWrap>("unary", this._transport, method, opt, input);
    }
    /**
     * List the latest messages for a specific thread
     *
     * @generated from protobuf rpc: ListMessages(messaging.bff.api.ListMessagesRequest) returns (messaging.bff.api.ListMessagesResponse);
     */
    listMessages(input: ListMessagesRequest, options?: RpcOptions): UnaryCall<ListMessagesRequest, ListMessagesResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessagesRequest, ListMessagesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Create a new thread with the first message
     *
     * @generated from protobuf rpc: CreateThread(messaging.bff.api.CreateThreadRequest) returns (messaging.bff.api.ThreadWrap);
     */
    createThread(input: CreateThreadRequest, options?: RpcOptions): UnaryCall<CreateThreadRequest, ThreadWrap> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateThreadRequest, ThreadWrap>("unary", this._transport, method, opt, input);
    }
    /**
     * List all threads with the latest message
     *
     * @generated from protobuf rpc: ListThreads(messaging.bff.api.ListThreadsRequest) returns (messaging.bff.api.ListThreadsResponse);
     */
    listThreads(input: ListThreadsRequest, options?: RpcOptions): UnaryCall<ListThreadsRequest, ListThreadsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListThreadsRequest, ListThreadsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get the information of a single thread
     *
     * @generated from protobuf rpc: GetThread(messaging.bff.api.GetThreadRequest) returns (messaging.bff.api.ThreadWrap);
     */
    getThread(input: GetThreadRequest, options?: RpcOptions): UnaryCall<GetThreadRequest, ThreadWrap> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetThreadRequest, ThreadWrap>("unary", this._transport, method, opt, input);
    }
    /**
     * Get a message attachment
     *
     * @generated from protobuf rpc: GetMessageAttachment(messaging.bff.api.GetMessageAttachmentRequest) returns (messaging.bff.api.MessageAttachment);
     */
    getMessageAttachment(input: GetMessageAttachmentRequest, options?: RpcOptions): UnaryCall<GetMessageAttachmentRequest, MessageAttachment> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMessageAttachmentRequest, MessageAttachment>("unary", this._transport, method, opt, input);
    }
    /**
     * Get the human name of patient
     * Should only be called by Verily Enterprise
     *
     * @generated from protobuf rpc: GetParticipantRecordName(messaging.bff.api.GetParticipantRecordNameRequest) returns (messaging.bff.api.ParticipantRecord);
     */
    getParticipantRecordName(input: GetParticipantRecordNameRequest, options?: RpcOptions): UnaryCall<GetParticipantRecordNameRequest, ParticipantRecord> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetParticipantRecordNameRequest, ParticipantRecord>("unary", this._transport, method, opt, input);
    }
    /**
     * Get a list of approved hostnames for hyperlink previews.
     * Can be called by authenticated users
     *
     * @generated from protobuf rpc: GetHyperlinkPreviewAllowList(messaging.bff.api.GetHyperlinkPreviewAllowListRequest) returns (messaging.bff.api.GetHyperlinkPreviewAllowListResponse);
     */
    getHyperlinkPreviewAllowList(input: GetHyperlinkPreviewAllowListRequest, options?: RpcOptions): UnaryCall<GetHyperlinkPreviewAllowListRequest, GetHyperlinkPreviewAllowListResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetHyperlinkPreviewAllowListRequest, GetHyperlinkPreviewAllowListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get the resource needed for hyperlink preview.
     * Can be called by authenticated users
     *
     * @generated from protobuf rpc: GetHyperlinkPreviewResource(messaging.bff.api.GetHyperlinkPreviewResourceRequest) returns (messaging.bff.api.HyperlinkPreviewResource);
     */
    getHyperlinkPreviewResource(input: GetHyperlinkPreviewResourceRequest, options?: RpcOptions): UnaryCall<GetHyperlinkPreviewResourceRequest, HyperlinkPreviewResource> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetHyperlinkPreviewResourceRequest, HyperlinkPreviewResource>("unary", this._transport, method, opt, input);
    }
    /**
     * Add a reader for all messages of a thread
     *
     * @generated from protobuf rpc: SetThreadAsRead(messaging.bff.api.SetThreadAsReadRequest) returns (google.protobuf.Empty);
     */
    setThreadAsRead(input: SetThreadAsReadRequest, options?: RpcOptions): UnaryCall<SetThreadAsReadRequest, Empty> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetThreadAsReadRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Get Messaging Configuration for participant user
     *
     * @generated from protobuf rpc: GetMessagingConfiguration(messaging.bff.api.GetMessagingConfigurationRequest) returns (messaging.bff.api.MessagingConfiguration);
     */
    getMessagingConfiguration(input: GetMessagingConfigurationRequest, options?: RpcOptions): UnaryCall<GetMessagingConfigurationRequest, MessagingConfiguration> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMessagingConfigurationRequest, MessagingConfiguration>("unary", this._transport, method, opt, input);
    }
}
