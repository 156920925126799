import {Snackbar} from '@verily-src/react-design-system';
import {Timestamp} from '@verily-src/verily1-protos/google/protobuf/timestamp';
import {
  Message as MessageType,
  MessageWrap,
} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import createBFFClient from '../../../api/createBFFClient';
import {createMessage} from '../../../api/createMessage';
import {listMessages} from '../../../api/listMessages';
import {setThreadAsRead} from '../../../api/setThreadAsRead';
import {pubTrigger} from '../../../background/utility/pollingNotifier';
import {getThreads} from '../../../background/utility/threadNotifier';
import {useThemeContext} from '../../components/ThemeContext/ThemeContext';
import createAttachmentUrl from '../../utility/createAttachmentUrl';
import {FileAttachmentState} from '../../utility/custComponentProps';
import {MessagingState, PageState} from '../../utility/messagingState';
const pollingInterval = 5000;

export type ThreadDetailProps = {
  redirect: (a: MessagingState) => void;
  threadId: string;
};

export default function ThreadDetail(props: ThreadDetailProps) {
  const {t} = useTranslation();
  const themeCont = useThemeContext();
  const applicationSuite = themeCont.applicationSuite;
  const styles = themeCont.styles.ThreadDetail;
  const MessageContainer = themeCont.components.ThreadDetail.MessageContainer;
  const SendBox = themeCont.components.ThreadDetail.SendBox;
  const Header = themeCont.components.ThreadDetail.Header;
  const applicationSuiteData = themeCont?.applicationSuiteData;
  const professionalAccountRefName =
    applicationSuiteData?.professionalAccountRefName;
  const participantRecordRefName =
    applicationSuiteData?.participantRecordRefName;
  const [errorCreating, setErrorCreating] = useState<Error>(null);
  const [snackbarText, setSnackbarText] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [threadName, setThreadName] = useState<string>('');
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [fileAttachment, setFileAttachment] =
    useState<FileAttachmentState>(null);
  const [messageContent, setMessageContent] = useState<string>('');
  type MessageState = {
    messages: MessageWrap[];
    maxTimestamp: number;
  };
  const [messageState, setMessageState] = useState<MessageState>({
    messages: [],
    maxTimestamp: 0,
  });
  const [updateTimeInt, setUpdateTimeInt] = useState<number>(0);
  const textFieldId = 'messaging_threaddetail_textbody';
  const loadingAria = loading
    ? {
        'aria-describedby': t('ariaLoading_threadDetail_common'),
        'aria-busy': true,
      }
    : {};
  const client = createBFFClient();
  // Get thread title from thread observable.
  useEffect(() => {
    if (threadName) {
      return;
    }
    const sub = getThreads().subscribe({
      next: obs => {
        const threadItem = obs.threads.find(
          e => e.thread.threadRefName === `threads/${props.threadId}`
        );
        const threadTitle = threadItem?.thread.title;
        if (threadTitle) {
          setThreadName(threadTitle);
        }
      },
    });
    return () => {
      sub.unsubscribe();
    };
  }, [props.threadId, threadName]);
  // Poll listMessages.
  useEffect(() => {
    const messageUpdate = async () => {
      try {
        const messagesReceived = (
          await listMessages(
            client,
            {
              timeRange: {
                lowerBound: Timestamp.fromDate(
                  new Date(messageState.maxTimestamp)
                ),
              },
              noAttachmentBlob: true,
            },
            props.threadId,
            participantRecordRefName,
            professionalAccountRefName,
            applicationSuite
          )
        ).messageWraps as MessageWrap[];
        // Deduplicate messages.
        if (messagesReceived.length > 0) {
          const messagesIds = messagesReceived.map(
            m => m.message.messageRefName
          );
          const currentMessages = messageState.messages.filter(
            m => !messagesIds.includes(m.message.messageRefName)
          );
          setMessageState({
            messages: [...messagesReceived, ...currentMessages],
            maxTimestamp: Timestamp.toDate(
              messagesReceived[0].message.sentTime
            ).getTime(),
          });
          // Set thread as read.
          if (!messagesReceived[0].message.isRead) {
            await setThreadAsRead(
              client,
              props.threadId,
              professionalAccountRefName,
              participantRecordRefName,
              applicationSuite
            );
            pubTrigger();
          }
        }
        loading && setLoading(false);
      } catch (err) {
        console.log(err);
        !loading && setLoading(true);
      }
    };
    messageUpdate();
    const nextUpdate = setTimeout(() => {
      setUpdateTimeInt(new Date().getTime());
    }, pollingInterval);
    return () => {
      clearTimeout(nextUpdate);
    };
  }, [props.threadId, updateTimeInt]);
  // Set this snackbar text only once
  useEffect(() => {
    if (snackbarText === '' && !!errorCreating) {
      setSnackbarText(
        errorCreating?.toString() === 'PERMISSION_DENIED'
          ? 'Permission Denied'
          : t('messageCreationFailed_threadDetail_common')
      );
    }
  }, [errorCreating, setErrorCreating]);

  const sendIconOnClick = async () => {
    setButtonLoading(true);
    try {
      const attachmentData = fileAttachment
        ? {
            messageAttachmentId: '',
            blob: fileAttachment.data,
            mimeType: fileAttachment.type,
            metadata: {
              size: fileAttachment.meta.size,
              title: fileAttachment.meta.title,
            },
          }
        : undefined;
      const message: MessageType = {
        threadId: props.threadId,
        senderRefName: '',
        messageRefName: '',
        messageData: {
          messageText: messageContent.trim(),
          attachment: attachmentData,
        },
        isRead: false,
        isReadByParticipant: false,
      };
      await createMessage(
        client,
        message,
        participantRecordRefName,
        professionalAccountRefName,
        applicationSuite
      );
      setFileAttachment(null);
      setMessageContent('');
      setUpdateTimeInt(new Date().getTime());
    } catch (err) {
      console.log(err);
      setErrorCreating(err);
    }
    setButtonLoading(false);
    const sendTextBox = document.getElementById(textFieldId);
    !errorCreating &&
      sendTextBox.setAttribute(
        'aria-label',
        t('ariaMessageSent_threadDetail_common')
      );
    sendTextBox.focus();
    setTimeout(() => sendTextBox.removeAttribute('aria-label'), 100);
  };
  const attachIconHandler = async attachment => {
    const data = new Uint8Array(await attachment.arrayBuffer());
    setFileAttachment({
      data: data,
      type: attachment.type,
      url: createAttachmentUrl(data, attachment.type),
      meta: {title: attachment.name, size: attachment.size},
    });
    const sendTextBox = document.getElementById(textFieldId);
    sendTextBox.setAttribute(
      'aria-label',
      attachment.type.startsWith('image')
        ? t('ariaPhotoAttached_common_common')
        : t('ariaFileAttached_common_common')
    );
    sendTextBox.focus();
    const removeAriaLabel = setTimeout(
      () => sendTextBox.removeAttribute('aria-label'),
      3000
    );
    return () => clearTimeout(removeAriaLabel);
  };
  const closeAttachHandler = async () => {
    setFileAttachment(null);
    const sendTextBox = document.getElementById(textFieldId);
    sendTextBox.setAttribute(
      'aria-label',
      t('ariaAttachmentRemoved_common_common')
    );
    sendTextBox.focus();
    const removeAriaLabel = setTimeout(
      () => sendTextBox.removeAttribute('aria-label'),
      2000
    );
    return () => clearTimeout(removeAriaLabel);
  };

  return (
    <div className={styles.Overall} {...loadingAria}>
      <Header
        threadName={threadName}
        onClickHandler={() => {
          props.redirect({page: PageState.ThreadsOverview});
        }}
      />
      <MessageContainer
        messages={messageState.messages}
        threadName={threadName}
        loading={loading || buttonLoading}
      />
      <SendBox
        textFieldId={textFieldId}
        textBoxHandler={e => {
          setMessageContent(e.target.value.trimStart());
        }}
        sendIconHandler={sendIconOnClick}
        attachIconHandler={attachIconHandler}
        closeAttachHandler={closeAttachHandler}
        textBoxValue={messageContent}
        buttonDisable={(!messageContent && !fileAttachment) || buttonLoading}
        fileAttachment={fileAttachment}
      />
      <Snackbar
        open={!!errorCreating && !!snackbarText}
        color="error"
        onClose={() => setErrorCreating(null)}
        text={snackbarText}
      />
    </div>
  );
}
