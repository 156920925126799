import {Snackbar} from '@verily-src/react-design-system';
import {Thread} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import createBFFClient from '../../../api/createBFFClient';
import {createThread} from '../../../api/createThread';
import {pubTrigger} from '../../../background/utility/pollingNotifier';
import LoadingCircularProgress from '../../components/LoadingCircularProgress/LoadingCircularProgress';
import {useThemeContext} from '../../components/ThemeContext/ThemeContext';
import createAttachmentUrl from '../../utility/createAttachmentUrl';
import {FileAttachmentState} from '../../utility/custComponentProps';
import {MessagingState, PageState} from '../../utility/messagingState';

export type CreateThreadProps = {
  redirect: (a: MessagingState) => void;
};

export default function CreateThread(props: CreateThreadProps) {
  const {t} = useTranslation();
  const themeCont = useThemeContext();
  const applicationSuite = themeCont.applicationSuite;
  const styles = themeCont.styles.CreateThread;
  const SendBox = themeCont.components.CreateThread.SendBox;
  const Header = themeCont.components.CreateThread.Header;
  const applicationSuiteData = themeCont?.applicationSuiteData;
  const professionalAccountRefName =
    applicationSuiteData?.professionalAccountRefName;
  const participantRecordRefName =
    applicationSuiteData?.participantRecordRefName;
  const [errorCreating, setErrorCreating] = useState<Error>(null);
  const [snackbarText, setSnackbarText] = useState('');
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [fileAttachment, setFileAttachment] =
    useState<FileAttachmentState>(null);
  const [conversationName, setConversationName] = useState<string>('');
  const [messageContent, setMessageContent] = useState<string>('');
  // Set this snackbar text only once
  useEffect(() => {
    if (snackbarText === '' && !!errorCreating) {
      setSnackbarText(
        errorCreating?.toString() === 'PERMISSION_DENIED'
          ? 'Permission Denied'
          : t('threadCreationFailed_createThread_common')
      );
    }
  }, [errorCreating, setErrorCreating]);

  const loadingAria = buttonLoading
    ? {
        'aria-describedby': t('ariaLoading_createThread_common'),
        'aria-busy': true,
      }
    : {};
  const textFieldId = 'messaging_createthread_textbody';
  const sendIconOnClick = async () => {
    setButtonLoading(true);
    try {
      const attachmentData = fileAttachment
        ? {
            messageAttachmentId: '',
            blob: fileAttachment.data,
            mimeType: fileAttachment.type,
            metadata: {
              size: fileAttachment.meta.size,
              title: fileAttachment.meta.title,
            },
          }
        : undefined;
      const thread: Thread = {
        threadRefName: '',
        title: conversationName.trim(),
        participantRecordId: '',
        latestMessage: {
          senderName: null,
          message: {
            threadId: null,
            senderRefName: '',
            messageRefName: '',
            messageData: {
              messageText: messageContent.trim(),
              attachment: attachmentData,
            },
            isRead: false,
            isReadByParticipant: false,
          },
        },
      };
      const threadCreated = await createThread(
        createBFFClient(),
        thread,
        professionalAccountRefName,
        participantRecordRefName,
        applicationSuite
      );
      const threadId = threadCreated.thread.threadRefName.replace(
        /^(threads\/)/,
        ''
      );
      pubTrigger();
      props.redirect({page: PageState.ThreadDetail, threadId: threadId});
    } catch (err) {
      console.log(err);
      setErrorCreating(err);
    }
    setButtonLoading(false);
  };
  const attachIconHandler = async attachment => {
    const data = new Uint8Array(await attachment.arrayBuffer());
    setFileAttachment({
      data: data,
      type: attachment.type,
      url: createAttachmentUrl(data, attachment.type),
      meta: {title: attachment.name, size: attachment.size},
    });
    const sendTextBox = document.getElementById(textFieldId);
    sendTextBox.setAttribute(
      'aria-label',
      attachment.type.startsWith('image')
        ? t('ariaPhotoAttached_common_common')
        : t('ariaFileAttached_common_common')
    );

    sendTextBox.focus();
    const removeAriaLabel = setTimeout(() => {
      sendTextBox.removeAttribute('aria-label');
    }, 3000);
    return () => clearTimeout(removeAriaLabel);
  };
  const closeAttachHandler = async () => {
    setFileAttachment(null);
    const sendTextBox = document.getElementById(textFieldId);
    sendTextBox.setAttribute(
      'aria-label',
      t('ariaAttachmentRemoved_common_common')
    );
    sendTextBox.focus();
    const removeAriaLabel = setTimeout(
      () => sendTextBox.removeAttribute('aria-label'),
      2000
    );
    return () => clearTimeout(removeAriaLabel);
  };
  return (
    <div className={styles.Overall} {...loadingAria}>
      <Header
        onClickHandler={() => {
          props.redirect({page: PageState.ThreadsOverview});
        }}
      />
      <LoadingCircularProgress loading={buttonLoading} />
      <SendBox
        textFieldId={textFieldId}
        threadBoxHandler={e => {
          setConversationName(e.target.value.trimStart());
        }}
        messageBoxHandler={e => {
          setMessageContent(e.target.value.trimStart());
        }}
        sendIconHandler={sendIconOnClick}
        attachIconHandler={attachIconHandler}
        closeAttachHandler={closeAttachHandler}
        messageContent={messageContent}
        fileAttachment={fileAttachment}
        conversationName={conversationName}
        buttonLoading={buttonLoading}
      />
      <Snackbar
        open={!!errorCreating && !!snackbarText}
        color="error"
        onClose={() => setErrorCreating(null)}
        text={snackbarText}
      />
    </div>
  );
}
