import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';

const getBaseUrl = () =>
  `${window.location.protocol}//${window.location.host}/api`;

export default () =>
  new MessagingBFFServiceClient(
    new GrpcWebFetchTransport({
      baseUrl: String(PHAF_INJECTED_VALUES.BFF_BASE_URL) || getBaseUrl(),
    })
  );
