import {Timestamp} from '@verily-src/verily1-protos/google/protobuf/timestamp';
import {TFunction} from 'i18next';
import {DateTime} from 'luxon';

export const minuteMs = 60 * 1000;
export const hourMs = 60 * minuteMs;
export const dayMs = 24 * hourMs;

export function dateFormat(timepb: Timestamp): string {
  const date = Timestamp.toDate(timepb);
  const monthStr = ('0' + (date.getMonth() + 1).toString()).substr(-2);
  const dateStr = ('0' + date.getDate().toString()).substr(-2);
  const yearStr = date.getFullYear().toString().substr(2);
  return `${monthStr}/${dateStr}/${yearStr}`;
}

export function dateMedFormat(timepb: Timestamp): string {
  const date = Timestamp.toDate(timepb);
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED);
}

export default (timepb: Timestamp, t: TFunction): string => {
  const timeDate = Timestamp.toDate(timepb);
  const currentTime = new Date();
  const timeDiff = currentTime.getTime() - timeDate.getTime();
  /** If message received within the last 1min. */
  if (timeDiff < minuteMs) {
    return t('time_since_just_now_verilyMe');
  }
  /** If message received within the last hour. */
  if (timeDiff < hourMs) {
    return `${Math.ceil(timeDiff / minuteMs)} ${t(
      'time_since_min_ago_verilyMe'
    )}`;
  }
  /** If message received within the last day. */
  if (timeDiff < dayMs) {
    return `${Math.ceil(timeDiff / hourMs)} ${t(
      'time_since_hours_ago_verilyMe'
    )}`;
  }
  /** If message received within the 7 day. */
  const dayDiff = Math.ceil(timeDiff / dayMs - currentTime.getHours() / 24);
  if (dayDiff < 7) {
    return dayDiff < 2
      ? t('time_since_yesterday_verilyMe')
      : `${dayDiff} ${t('time_since_days_ago_verilyMe')}`;
  }
  /** Message received 30 days before, show dates instead. */
  return dateFormat(timepb);
};
