import {Box} from '@mui/material';
import {VerilyLogoIcon} from '@verily-src/react-design-system';

export const ORGANIZATION_NAME = 'Verily Team';

export function VerilyLogo() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        backgroundColor: 'primary.main',
        height: '24px',
        width: '24px',
        border: 0.5,
        borderColor: 'background.paper',
      }}
    >
      <VerilyLogoIcon
        sx={{
          height: '20px',
          width: '20px',
          ['& path']: {fill: 'white', fillOpacity: '1'},
        }}
      />
    </Box>
  );
}
