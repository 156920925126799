import {alpha, Typography, useTheme} from '@mui/material';
import {Tag} from '@verily-src/react-design-system';
import {useCallback, useEffect, useState} from 'react';
import {ThreadsOverviewThreadProps} from '../../../../foreground/utility/custComponentProps';
import timeConverter from '../Common/timeConverter';
import styles from './Thread.css';

export const getPreText = fileType => {
  if (fileType?.startsWith('image/') || fileType === '') {
    return '[image] ';
  }
  return fileType ? '[file] ' : '';
};

export default function Thread(props: ThreadsOverviewThreadProps) {
  const focusRef = useCallback(inputElement => {
    if (props.autoFocus && inputElement) {
      inputElement.focus();
    }
  }, []);
  const [hover, setHover] = useState<boolean>(false);
  const [ariaLabel, setAriaLabel] = useState<string>(props.ariaLabel);
  const theme = useTheme();

  useEffect(() => {
    // Let the VoiceOver anounce all text in each message including 'Unreplied'
    let ariaLabelText = `${props.ariaLabel}, ${timeConverter(
      props.thread.latestMessage.message.sentTime
    )}`;
    const messageText =
      props.thread.latestMessage.message.messageData.messageText;
    if (
      props.thread.latestMessage.message.senderRefName.startsWith(
        'participantRecords/'
      )
    ) {
      ariaLabelText += `, ${messageText}, Unreplied`;
    } else {
      ariaLabelText += `, ${props.thread.latestMessage.senderName} has replied, ${messageText}`;
    }
    setAriaLabel(ariaLabelText);
  }, [props.thread]);

  const preText = getPreText(
    props.thread.latestMessage.message.messageData?.attachment?.mimeType
  );
  return (
    <button
      id={props.thread.threadRefName}
      ref={focusRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderWidth: '0px 0px 0.5px 0px',
        borderColor: theme.palette.background.separator,
        textAlign: 'left',
        borderRadius: hover ? '4px' : '0px',
        padding: '12px 5px 12px 5px',
        backgroundColor: hover
          ? alpha(theme.palette.neutral.divider, 0.25)
          : theme.palette.background.paper,
      }}
      onClick={props.redirect}
      aria-label={ariaLabel}
    >
      <div className={styles.ThreadMD}>
        <Typography
          data-testid="thread-title"
          variant="body2"
          sx={{wordBreak: 'break-word'}}
        >
          <strong>{props.thread.title}</strong>
        </Typography>
        <Typography
          data-testid="thread-date"
          variant="body2"
          color="text.muted"
        >
          {timeConverter(props.thread.latestMessage.message.sentTime)}
        </Typography>
      </div>
      {props.thread.latestMessage.message.senderRefName.startsWith(
        'participantRecords/'
      ) ? (
        <Tag label="Unreplied" color="success" />
      ) : (
        <Typography
          data-testid="thread-replied"
          variant="body2"
          color="text.muted"
        >
          {`${props.thread.latestMessage.senderName} has replied`}
        </Typography>
      )}
      <div className={styles.ThreadLastMessage}>
        <Typography
          data-testid="thread-details"
          variant="body2"
          sx={{wordBreak: 'break-word'}}
        >
          {preText}
          {props.thread.latestMessage.message.messageData.messageText}
        </Typography>
      </div>
    </button>
  );
}
