// Need to hoist public path setting to top so ES6 imports do not come before it.
import {base64encode} from '@protobuf-ts/runtime';
import {init as initL10n} from '@verily-src/l10n/src/l10n';
import '@verily-src/phaf-runtime-helpers';
import {
  BatchCheckAuthorizationResponse,
  ListAuthorizedOrganizationsResponse,
} from '@verily-src/verily1-protos/ciam/backend/api/v1/ciam';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import './publicPath';
import reportWebVitals from './reportWebVitals';
import Root from './Root/Root';

function generateFakeAccessToken(permissions: string[]) {
  // eslint-disable-next-line node/no-extraneous-require
  const jwt = require('jsonwebtoken');

  const encodedbatchCheckAuthorizationResponse = base64encode(
    BatchCheckAuthorizationResponse.toBinary({
      permissions: permissions,
      targetPermissions: {},
    })
  );

  const encodedListAuthorizedOrganizationsResponse = base64encode(
    ListAuthorizedOrganizationsResponse.toBinary({
      organizations: ['organizations/cf58e801-3a4d-44f6-903b-3c9916da8b85'],
    })
  );

  const accessToken = jwt.sign(
    {
      emailAddr: '',
      personId: 'personId',
      sub: 'sub',
      expected_checkauthorization_error_code: 0,
      expected_batchcheckauthorization_response:
        encodedbatchCheckAuthorizationResponse,
      expected_batchcheckauthorization_error_code: 0,
      expected_listauthzorgs_response:
        encodedListAuthorizedOrganizationsResponse,
      expected_listauthzorgs_error_code: 0,
      Subject: 'subject',
      Issuer: 'verily-us-autopush-pro.us.auth0.com',
      Audience: ['local'],
    },
    'secret'
  );
  return accessToken;
}

if (
  !PHAF_INJECTED_VALUES.REACT_APP_RUN_BDD &&
  PHAF_INJECTED_VALUES.NODE_ENV !== 'production'
) {
  const token = generateFakeAccessToken(['PARTICIPANT_ID']);
  window.localStorage.setItem('test_access_token', token);
}

initL10n(f => require(`./l10n/locales/${f}`));
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const {bootstrap, mount, unmount} = lifecycles;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
