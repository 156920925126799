/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "messaging/bff/api/messaging_bff_service.proto" (package "messaging.bff.api", syntax proto3)
// tslint:disable
// @ts-nocheck
import { Empty } from "../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * Request message for creating a new message.
 *
 * @generated from protobuf message messaging.bff.api.CreateMessageRequest
 */
export interface CreateMessageRequest {
    /**
     * A unique ID to guarantee idempotency for this request. Optional.
     *
     * A random UUID is recommended. The request is only idempotent if the
     * `request_id` field is provided. See go/aip/155
     *
     * @generated from protobuf field: string request_id = 1;
     */
    requestId: string;
    /**
     * Message to create
     *
     * @generated from protobuf field: messaging.bff.api.Message message = 2;
     */
    message?: Message;
    /**
     * The participant record ID that will receive the message.
     * Format: participantRecords/{fhir_patient_id}
     * Optional, only needed when the message is created by professional accounts.
     *
     * @generated from protobuf field: string participant_record_ref_name = 3;
     */
    participantRecordRefName: string;
    /**
     * The ID for professional account who sends the request.
     * Format: professionalAccounts/{fhir_practitioner_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string professional_account_ref_name = 4;
     */
    professionalAccountRefName: string;
    /**
     * Field to specify where the request is from
     *
     * @generated from protobuf field: messaging.bff.api.ApplicationSuite application_suite = 5;
     */
    applicationSuite: ApplicationSuite;
}
/**
 * Request message for listing messages.
 *
 * @generated from protobuf message messaging.bff.api.ListMessagesRequest
 */
export interface ListMessagesRequest {
    /**
     * @generated from protobuf field: messaging.bff.api.ListMessagesRequest.Filter filter = 1;
     */
    filter?: ListMessagesRequest_Filter;
    /**
     * The id for the thread that the requested messages belong to
     *
     * @generated from protobuf field: string thread_id = 2;
     */
    threadId: string;
    /**
     * The ID for professional account who sends the request.
     * Format: professionalAccounts/{fhir_practitioner_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string professional_account_ref_name = 3;
     */
    professionalAccountRefName: string;
    /**
     * The ID for participant record that the threads belongs to.
     * Format: participantRecords/{fhir_patient_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string participant_record_ref_name = 4;
     */
    participantRecordRefName: string;
    // A page token, received from a previous `ListMessages` call.
    // Provide this to retrieve the subsequent page.

    /**
     * When paginating, all other parameters provided to `ListMessages` must
     * match the call that provided the page token, or behavior will be undefined.
     *
     * @generated from protobuf field: string page_token = 5;
     */
    pageToken: string;
    /**
     * Field to specify where the request is from
     *
     * @generated from protobuf field: messaging.bff.api.ApplicationSuite application_suite = 6;
     */
    applicationSuite: ApplicationSuite;
}
/**
 * Filters on messages to list.
 *
 * @generated from protobuf message messaging.bff.api.ListMessagesRequest.Filter
 */
export interface ListMessagesRequest_Filter {
    /**
     * @generated from protobuf field: messaging.bff.api.TimeRange time_range = 2;
     */
    timeRange?: TimeRange;
    /**
     * Not return attachment blob in response.
     * If unspecified, default to false, will include blob in response.
     *
     * @generated from protobuf field: bool no_attachment_blob = 3;
     */
    noAttachmentBlob: boolean;
}
/**
 * Response message for listing messages.
 *
 * @generated from protobuf message messaging.bff.api.ListMessagesResponse
 */
export interface ListMessagesResponse {
    /**
     * The list of messages in reverse chronological order of updated time
     * A MessageWrap contains the message and its sender's name.
     *
     * @generated from protobuf field: repeated messaging.bff.api.MessageWrap message_wraps = 1;
     */
    messageWraps: MessageWrap[];
    /**
     * A token that can be sent as `page_token` to retrieve the next page.
     * If this field is omitted, there are no subsequent pages.
     *
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * Request message for creating a thread
 *
 * @generated from protobuf message messaging.bff.api.CreateThreadRequest
 */
export interface CreateThreadRequest {
    /**
     * @generated from protobuf field: string request_id = 1;
     */
    requestId: string;
    /**
     * The thread to create.
     *
     * @generated from protobuf field: messaging.bff.api.Thread thread = 2;
     */
    thread?: Thread;
    /**
     * The ID for professional account who sends the request.
     * Format: professionalAccounts/{fhir_practitioner_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string professional_account_ref_name = 3;
     */
    professionalAccountRefName: string;
    /**
     * The ID for the participant record who the thread belongs to.
     * Format: participantRecords/{fhir_patient_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string participant_record_ref_name = 4;
     */
    participantRecordRefName: string;
    /**
     * Field to specify where the request is from
     *
     * @generated from protobuf field: messaging.bff.api.ApplicationSuite application_suite = 5;
     */
    applicationSuite: ApplicationSuite;
}
/**
 * Request message for listing threads for participant record.
 *
 * @generated from protobuf message messaging.bff.api.ListThreadsRequest
 */
export interface ListThreadsRequest {
    /**
     * @generated from protobuf field: messaging.bff.api.ListThreadsRequest.Filter filter = 1;
     */
    filter?: ListThreadsRequest_Filter;
    /**
     * The ID for participant record that the threads belongs to.
     * Format: participantRecords/{fhir_patient_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string participant_record_ref_name = 2;
     */
    participantRecordRefName: string;
    /**
     * The ID for professional account who sends the request.
     * Format: professionalAccounts/{fhir_practitioner_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string professional_account_ref_name = 3;
     */
    professionalAccountRefName: string;
    /**
     * A page token, received from a previous `ListThreadsWithMessages` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `ListThreadsWithMessages`
     * must match the call that provided the page token, or behavior will be
     * undefined.
     *
     * @generated from protobuf field: string page_token = 4;
     */
    pageToken: string;
    /**
     * Field to specify where the request is from
     *
     * @generated from protobuf field: messaging.bff.api.ApplicationSuite application_suite = 5;
     */
    applicationSuite: ApplicationSuite;
}
/**
 * Filters on threads to list.
 *
 * @generated from protobuf message messaging.bff.api.ListThreadsRequest.Filter
 */
export interface ListThreadsRequest_Filter {
    /**
     * The time range to restrict requested messages to. If unspecified,
     * defaults to listing all messages. The time means the time when
     * the backend API created the message after receiving it from a client.
     *
     * @generated from protobuf field: messaging.bff.api.TimeRange time_range = 2;
     */
    timeRange?: TimeRange;
}
/**
 * Response message for listing threads for participant record.
 *
 * @generated from protobuf message messaging.bff.api.ListThreadsResponse
 */
export interface ListThreadsResponse {
    /**
     * List of threads with the latest message returned in reverse chronological
     * order of the latest message (last updated) in the thread.
     *
     * @generated from protobuf field: repeated messaging.bff.api.ThreadWrap thread_wraps = 1;
     */
    threadWraps: ThreadWrap[];
    /**
     * A token that can be sent as `page_token` to retrieve the next page.
     * If this field is omitted, there are no subsequent pages.
     *
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * Request message with ref_name to identify a thread.
 *
 * @generated from protobuf message messaging.bff.api.GetThreadRequest
 */
export interface GetThreadRequest {
    /**
     * The resource name of the thread to retrieve
     * Format: threads/{thread_id}
     *
     * @generated from protobuf field: string thread_ref_name = 1;
     */
    threadRefName: string;
    /**
     * The ID for professional account who sends the request.
     * Format: professionalAccounts/{fhir_practitioner_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string professional_account_ref_name = 2;
     */
    professionalAccountRefName: string;
    /**
     * The ID for the participant record who the thread belongs to.
     * Format: participantRecords/{fhir_patient_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string participant_record_ref_name = 3;
     */
    participantRecordRefName: string;
    /**
     * Field to specify where the request is from
     *
     * @generated from protobuf field: messaging.bff.api.ApplicationSuite application_suite = 4;
     */
    applicationSuite: ApplicationSuite;
}
/**
 * Request message with ref_name to identify a message attachment.
 *
 * @generated from protobuf message messaging.bff.api.GetMessageAttachmentRequest
 */
export interface GetMessageAttachmentRequest {
    /**
     * Id of the FHIR resource storing the attachment
     * format: {message_attachment_id}
     *
     * @generated from protobuf field: string message_attachment_id = 1;
     */
    messageAttachmentId: string;
    /**
     * The ID for professional account who sends the request.
     * Format: professionalAccounts/{fhir_practitioner_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string professional_account_ref_name = 2;
     */
    professionalAccountRefName: string;
    /**
     * The ID for participant record that the attachment belongs/targets to.
     * Format: participantRecords/{fhir_patient_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string participant_record_ref_name = 3;
     */
    participantRecordRefName: string;
    /**
     * Field to specify where the request is from
     *
     * @generated from protobuf field: messaging.bff.api.ApplicationSuite application_suite = 4;
     */
    applicationSuite: ApplicationSuite;
}
/**
 * Request message with participant record id to get patient human name
 *
 * @generated from protobuf message messaging.bff.api.GetParticipantRecordNameRequest
 */
export interface GetParticipantRecordNameRequest {
    /**
     * Format: participantRecords/{fhir_patient_id}
     *
     * @generated from protobuf field: string participant_record_ref_name = 1;
     */
    participantRecordRefName: string;
    /**
     * The ID for ProfessionalAccount who sends the request.
     * Format: professionalAccounts/{fhir_practitioner_id}
     * This API is only for Verily Enterprise, so this field should always be set
     *
     * @generated from protobuf field: string professional_account_ref_name = 2;
     */
    professionalAccountRefName: string;
    /**
     * Field to specify where the request is from
     *
     * @generated from protobuf field: messaging.bff.api.ApplicationSuite application_suite = 3;
     */
    applicationSuite: ApplicationSuite;
}
/**
 * @generated from protobuf message messaging.bff.api.GetHyperlinkPreviewAllowListRequest
 */
export interface GetHyperlinkPreviewAllowListRequest {
}
/**
 * @generated from protobuf message messaging.bff.api.GetHyperlinkPreviewAllowListResponse
 */
export interface GetHyperlinkPreviewAllowListResponse {
    /**
     * List of approved hostnames for hyperlink previews.
     *
     * @generated from protobuf field: repeated string hostnames = 1;
     */
    hostnames: string[];
}
/**
 * Request message for setting a thread as read.
 *
 * @generated from protobuf message messaging.bff.api.SetThreadAsReadRequest
 */
export interface SetThreadAsReadRequest {
    /**
     * The thread resource shared by all messages as parent being updated.
     * Format: threads/{thread_id}
     *
     * @generated from protobuf field: string thread_ref_name = 1;
     */
    threadRefName: string;
    /**
     * The ID for professional account who sends the request.
     * Format: professionalAccounts/{fhir_practitioner_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string professional_account_ref_name = 2;
     */
    professionalAccountRefName: string;
    /**
     * The ID for the participant record who the thread belongs to.
     * Format: participantRecords/{fhir_patient_id}
     * Optional, should be set when called by professional accounts.
     *
     * @generated from protobuf field: string participant_record_ref_name = 3;
     */
    participantRecordRefName: string;
    /**
     * Field to specify where the request is from
     *
     * @generated from protobuf field: messaging.bff.api.ApplicationSuite application_suite = 4;
     */
    applicationSuite: ApplicationSuite;
}
/**
 * Request message for getting Messaging Configuration for participant user
 *
 * @generated from protobuf message messaging.bff.api.GetMessagingConfigurationRequest
 */
export interface GetMessagingConfigurationRequest {
    /**
     * Field to specify where the request is from
     *
     * @generated from protobuf field: messaging.bff.api.ApplicationSuite application_suite = 1;
     */
    applicationSuite: ApplicationSuite;
}
/**
 * MessagingConfiguration containing information on whether the participant user
 * has messaging enabled.
 *
 * @generated from protobuf message messaging.bff.api.MessagingConfiguration
 */
export interface MessagingConfiguration {
    /**
     * Format: true if the participant user has messaging enabled, otherwise
     * false.
     *
     * @generated from protobuf field: bool is_messaging_enabled = 1;
     */
    isMessagingEnabled: boolean;
    /**
     * List of messaging-enabled participant records and their associated programs
     *
     * @generated from protobuf field: repeated messaging.bff.api.EnabledParticipantRecord enabled_participant_records = 2;
     */
    enabledParticipantRecords: EnabledParticipantRecord[];
}
/**
 * @generated from protobuf message messaging.bff.api.EnabledParticipantRecord
 */
export interface EnabledParticipantRecord {
    /**
     * Format: List of participantRecords/{patient_id}
     *
     * @generated from protobuf field: string participant_record_ref_name = 1;
     */
    participantRecordRefName: string;
}
/**
 * @generated from protobuf message messaging.bff.api.GetHyperlinkPreviewResourceRequest
 */
export interface GetHyperlinkPreviewResourceRequest {
    /**
     * @generated from protobuf field: string hyperlink = 1;
     */
    hyperlink: string;
}
/**
 * @generated from protobuf message messaging.bff.api.HyperlinkPreviewResource
 */
export interface HyperlinkPreviewResource {
    /**
     * @generated from protobuf field: string hyperlink = 1;
     */
    hyperlink: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: bytes hero_image = 3;
     */
    heroImage: Uint8Array;
    /**
     * @generated from protobuf field: string mime_type = 4;
     */
    mimeType: string;
}
/**
 * A ThreadWrap contains a thread and the organization name that the participant
 * record is talking to.
 *
 * @generated from protobuf message messaging.bff.api.ThreadWrap
 */
export interface ThreadWrap {
    /**
     * @generated from protobuf field: messaging.bff.api.Thread thread = 1;
     */
    thread?: Thread;
    /**
     * The organization that the participant record is sending message to,
     * which is required for frontend display.
     *
     * @generated from protobuf field: messaging.bff.api.Organization organization = 2;
     */
    organization?: Organization;
}
/**
 * Represents organization information
 *
 * @generated from protobuf message messaging.bff.api.Organization
 */
export interface Organization {
    /**
     * Resource name of the organization.
     * Format: "organizations/{ID}"
     *
     * @generated from protobuf field: string organization_ref_name = 1;
     */
    organizationRefName: string;
    /**
     * The display name associated with this organization
     * Example: Astera Cancer Care
     *
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
}
/**
 * Represents a thread between a participant record and professional accounts.
 * Also contains the latest message of the thread,
 *
 * @generated from protobuf message messaging.bff.api.Thread
 */
export interface Thread {
    /**
     * The resource name of a specific thread.
     * Format: threads/{thread_id}
     * Don't set when creating thread.
     *
     * @generated from protobuf field: string thread_ref_name = 1;
     */
    threadRefName: string;
    /**
     * Thread's subject populated by sender when a new thread is created
     *
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * Which participant record that this thread is for.
     * Set when the thread is created by professional accounts.
     *
     * @generated from protobuf field: string participant_record_id = 3;
     */
    participantRecordId: string;
    /**
     * Thread's lastest message, should include sender's name.
     *
     * @generated from protobuf field: messaging.bff.api.MessageWrap latest_message = 4;
     */
    latestMessage?: MessageWrap;
}
/**
 * A MessageWrap includes a message and the sender's name and designation of the
 * message, which is required for frontend display.
 *
 * @generated from protobuf message messaging.bff.api.MessageWrap
 */
export interface MessageWrap {
    /**
     * @generated from protobuf field: messaging.bff.api.Message message = 1;
     */
    message?: Message;
    /**
     * The sender's name and designation of the message
     * Format: "Sarah Marsh, RN"
     *
     * @generated from protobuf field: string sender_name = 2;
     */
    senderName: string;
}
/**
 * A representation of messages sent between participant records and
 * professional accounts.
 *
 * @generated from protobuf message messaging.bff.api.Message
 */
export interface Message {
    /**
     * Format: messages/{message_id}
     * Don't set when creating message.
     *
     * @generated from protobuf field: string message_ref_name = 1;
     */
    messageRefName: string;
    /**
     * ID of thread that this message belongs to.
     *
     * @generated from protobuf field: string thread_id = 2;
     */
    threadId: string;
    /**
     * The time this message was received by the backend
     * after it was sent by participant record or professional account
     *
     * @generated from protobuf field: google.protobuf.Timestamp sent_time = 3;
     */
    sentTime?: Timestamp;
    /**
     * The time this message was last updated
     *
     * @generated from protobuf field: google.protobuf.Timestamp update_time = 4;
     */
    updateTime?: Timestamp;
    /**
     * Who sent the message. Can be either a participant record or a professional
     * account. e.g. "professionalAccounts/1" or "participantRecords/1" Noted that
     * if the Message is from a request, the sender is only set when the request
     * is from Verily Enterprise, otherwise should be left empty and is inferred
     * in BFF.
     *
     * @generated from protobuf field: string sender_ref_name = 5;
     */
    senderRefName: string;
    /**
     * @generated from protobuf field: messaging.bff.api.MessageData message_data = 6;
     */
    messageData?: MessageData;
    /**
     * Indicator of whether the requester has read the message
     *
     * @generated from protobuf field: bool is_read = 7;
     */
    isRead: boolean;
    /**
     * Indicator of whether the participant has read the message.
     * Should only be used by Messaging MFE in provider console
     *
     * @generated from protobuf field: bool is_read_by_participant = 8;
     */
    isReadByParticipant: boolean;
}
/**
 * Represents Message content.
 *
 * @generated from protobuf message messaging.bff.api.MessageData
 */
export interface MessageData {
    /**
     * The text contents of a message. This will be populated for all messages so
     * that unsupported rich messages have a corresponding text fallback.
     *
     * @generated from protobuf field: string message_text = 1;
     */
    messageText: string;
    /**
     * Any photo or file attachment in the message.
     *
     * @generated from protobuf field: messaging.bff.api.MessageAttachment attachment = 2;
     */
    attachment?: MessageAttachment;
}
/**
 * Represents an attachment
 *
 * @generated from protobuf message messaging.bff.api.MessageAttachment
 */
export interface MessageAttachment {
    /**
     * The resource name of the attachment, message_attachment_id is the id of a
     * FHIR resource storing the attachment.
     * Format: {message_attachment_id}
     *
     * @generated from protobuf field: string message_attachment_id = 1;
     */
    messageAttachmentId: string;
    /**
     * @generated from protobuf field: bytes blob = 2;
     */
    blob: Uint8Array;
    /**
     * Content type for the attachment
     * https://google.aip.dev/143#content-types
     * Examples: image/jpeg, image/png
     *
     * @generated from protobuf field: string mime_type = 3;
     */
    mimeType: string;
    /**
     * @generated from protobuf field: messaging.bff.api.MessageAttachment.Metadata metadata = 4;
     */
    metadata?: MessageAttachment_Metadata;
}
/**
 * Metadata for the attachment file.
 *
 * @generated from protobuf message messaging.bff.api.MessageAttachment.Metadata
 */
export interface MessageAttachment_Metadata {
    /**
     * File name. Eg, "something.pdf".
     *
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * Number of bytes of content. Eg, 1000000 for a file of 1Mb in size.
     *
     * @generated from protobuf field: uint32 size = 2;
     */
    size: number;
}
/**
 * Represents a range of timestamps, often used for querying/filtering events
 * by timestamp.
 *
 * @generated from protobuf message messaging.bff.api.TimeRange
 */
export interface TimeRange {
    /**
     * The lower limit of this time range, exclusive
     *
     * @generated from protobuf field: google.protobuf.Timestamp lower_bound = 1;
     */
    lowerBound?: Timestamp;
    /**
     * The upper limit of this time range, inclusive
     *
     * @generated from protobuf field: google.protobuf.Timestamp upper_bound = 2;
     */
    upperBound?: Timestamp;
}
/**
 * This is a simplified struct of ParticipantRecord that only contain resource
 * name and human name.
 *
 * @generated from protobuf message messaging.bff.api.ParticipantRecord
 */
export interface ParticipantRecord {
    /**
     * Resource name of the ParticipantRecord.
     * Format: "participantRecords/{ID}"
     *
     * @generated from protobuf field: string participant_record_ref_name = 1;
     */
    participantRecordRefName: string;
    /**
     * The human name for the ParticipantRecord
     * Example: Jeanne Brown
     *
     * @generated from protobuf field: string participant_record_name = 2;
     */
    participantRecordName: string;
}
/**
 * Specify which platform a request is from
 *
 * @generated from protobuf enum messaging.bff.api.ApplicationSuite
 */
export enum ApplicationSuite {
    /**
     * @generated from protobuf enum value: APPLICATION_SUITE_UNSPECIFIED = 0;
     */
    APPLICATION_SUITE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: VERILY_ME = 1;
     */
    VERILY_ME = 1,
    /**
     * @generated from protobuf enum value: VERILY_ENTERPRISE = 2;
     */
    VERILY_ENTERPRISE = 2,
    /**
     * @generated from protobuf enum value: ENROLLMENT = 3;
     */
    ENROLLMENT = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateMessageRequest$Type extends MessageType<CreateMessageRequest> {
    constructor() {
        super("messaging.bff.api.CreateMessageRequest", [
            { no: 1, name: "request_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "message", kind: "message", T: () => Message, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 4, name: "professional_account_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 5, name: "application_suite", kind: "enum", T: () => ["messaging.bff.api.ApplicationSuite", ApplicationSuite], options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<CreateMessageRequest>): CreateMessageRequest {
        const message = { requestId: "", participantRecordRefName: "", professionalAccountRefName: "", applicationSuite: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateMessageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMessageRequest): CreateMessageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string request_id */ 1:
                    message.requestId = reader.string();
                    break;
                case /* messaging.bff.api.Message message */ 2:
                    message.message = Message.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                case /* string participant_record_ref_name */ 3:
                    message.participantRecordRefName = reader.string();
                    break;
                case /* string professional_account_ref_name */ 4:
                    message.professionalAccountRefName = reader.string();
                    break;
                case /* messaging.bff.api.ApplicationSuite application_suite */ 5:
                    message.applicationSuite = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateMessageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string request_id = 1; */
        if (message.requestId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.requestId);
        /* messaging.bff.api.Message message = 2; */
        if (message.message)
            Message.internalBinaryWrite(message.message, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string participant_record_ref_name = 3; */
        if (message.participantRecordRefName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.participantRecordRefName);
        /* string professional_account_ref_name = 4; */
        if (message.professionalAccountRefName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.professionalAccountRefName);
        /* messaging.bff.api.ApplicationSuite application_suite = 5; */
        if (message.applicationSuite !== 0)
            writer.tag(5, WireType.Varint).int32(message.applicationSuite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.CreateMessageRequest
 */
export const CreateMessageRequest = new CreateMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessagesRequest$Type extends MessageType<ListMessagesRequest> {
    constructor() {
        super("messaging.bff.api.ListMessagesRequest", [
            { no: 1, name: "filter", kind: "message", T: () => ListMessagesRequest_Filter, options: { "verily.privacy.annotations.scrub": false } },
            { no: 2, name: "thread_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "verily.privacy.annotations.scrub": false } },
            { no: 3, name: "professional_account_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 4, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 5, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 6, name: "application_suite", kind: "enum", T: () => ["messaging.bff.api.ApplicationSuite", ApplicationSuite], options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<ListMessagesRequest>): ListMessagesRequest {
        const message = { threadId: "", professionalAccountRefName: "", participantRecordRefName: "", pageToken: "", applicationSuite: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListMessagesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessagesRequest): ListMessagesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* messaging.bff.api.ListMessagesRequest.Filter filter */ 1:
                    message.filter = ListMessagesRequest_Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* string thread_id */ 2:
                    message.threadId = reader.string();
                    break;
                case /* string professional_account_ref_name */ 3:
                    message.professionalAccountRefName = reader.string();
                    break;
                case /* string participant_record_ref_name */ 4:
                    message.participantRecordRefName = reader.string();
                    break;
                case /* string page_token */ 5:
                    message.pageToken = reader.string();
                    break;
                case /* messaging.bff.api.ApplicationSuite application_suite */ 6:
                    message.applicationSuite = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessagesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* messaging.bff.api.ListMessagesRequest.Filter filter = 1; */
        if (message.filter)
            ListMessagesRequest_Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string thread_id = 2; */
        if (message.threadId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.threadId);
        /* string professional_account_ref_name = 3; */
        if (message.professionalAccountRefName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.professionalAccountRefName);
        /* string participant_record_ref_name = 4; */
        if (message.participantRecordRefName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.participantRecordRefName);
        /* string page_token = 5; */
        if (message.pageToken !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pageToken);
        /* messaging.bff.api.ApplicationSuite application_suite = 6; */
        if (message.applicationSuite !== 0)
            writer.tag(6, WireType.Varint).int32(message.applicationSuite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.ListMessagesRequest
 */
export const ListMessagesRequest = new ListMessagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessagesRequest_Filter$Type extends MessageType<ListMessagesRequest_Filter> {
    constructor() {
        super("messaging.bff.api.ListMessagesRequest.Filter", [
            { no: 2, name: "time_range", kind: "message", T: () => TimeRange },
            { no: 3, name: "no_attachment_blob", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListMessagesRequest_Filter>): ListMessagesRequest_Filter {
        const message = { noAttachmentBlob: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListMessagesRequest_Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessagesRequest_Filter): ListMessagesRequest_Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* messaging.bff.api.TimeRange time_range */ 2:
                    message.timeRange = TimeRange.internalBinaryRead(reader, reader.uint32(), options, message.timeRange);
                    break;
                case /* bool no_attachment_blob */ 3:
                    message.noAttachmentBlob = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessagesRequest_Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* messaging.bff.api.TimeRange time_range = 2; */
        if (message.timeRange)
            TimeRange.internalBinaryWrite(message.timeRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool no_attachment_blob = 3; */
        if (message.noAttachmentBlob !== false)
            writer.tag(3, WireType.Varint).bool(message.noAttachmentBlob);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.ListMessagesRequest.Filter
 */
export const ListMessagesRequest_Filter = new ListMessagesRequest_Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessagesResponse$Type extends MessageType<ListMessagesResponse> {
    constructor() {
        super("messaging.bff.api.ListMessagesResponse", [
            { no: 1, name: "message_wraps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageWrap },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMessagesResponse>): ListMessagesResponse {
        const message = { messageWraps: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListMessagesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessagesResponse): ListMessagesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated messaging.bff.api.MessageWrap message_wraps */ 1:
                    message.messageWraps.push(MessageWrap.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessagesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated messaging.bff.api.MessageWrap message_wraps = 1; */
        for (let i = 0; i < message.messageWraps.length; i++)
            MessageWrap.internalBinaryWrite(message.messageWraps[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.ListMessagesResponse
 */
export const ListMessagesResponse = new ListMessagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateThreadRequest$Type extends MessageType<CreateThreadRequest> {
    constructor() {
        super("messaging.bff.api.CreateThreadRequest", [
            { no: 1, name: "request_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "thread", kind: "message", T: () => Thread, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "professional_account_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 4, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 5, name: "application_suite", kind: "enum", T: () => ["messaging.bff.api.ApplicationSuite", ApplicationSuite], options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<CreateThreadRequest>): CreateThreadRequest {
        const message = { requestId: "", professionalAccountRefName: "", participantRecordRefName: "", applicationSuite: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateThreadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateThreadRequest): CreateThreadRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string request_id */ 1:
                    message.requestId = reader.string();
                    break;
                case /* messaging.bff.api.Thread thread */ 2:
                    message.thread = Thread.internalBinaryRead(reader, reader.uint32(), options, message.thread);
                    break;
                case /* string professional_account_ref_name */ 3:
                    message.professionalAccountRefName = reader.string();
                    break;
                case /* string participant_record_ref_name */ 4:
                    message.participantRecordRefName = reader.string();
                    break;
                case /* messaging.bff.api.ApplicationSuite application_suite */ 5:
                    message.applicationSuite = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateThreadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string request_id = 1; */
        if (message.requestId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.requestId);
        /* messaging.bff.api.Thread thread = 2; */
        if (message.thread)
            Thread.internalBinaryWrite(message.thread, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string professional_account_ref_name = 3; */
        if (message.professionalAccountRefName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.professionalAccountRefName);
        /* string participant_record_ref_name = 4; */
        if (message.participantRecordRefName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.participantRecordRefName);
        /* messaging.bff.api.ApplicationSuite application_suite = 5; */
        if (message.applicationSuite !== 0)
            writer.tag(5, WireType.Varint).int32(message.applicationSuite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.CreateThreadRequest
 */
export const CreateThreadRequest = new CreateThreadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListThreadsRequest$Type extends MessageType<ListThreadsRequest> {
    constructor() {
        super("messaging.bff.api.ListThreadsRequest", [
            { no: 1, name: "filter", kind: "message", T: () => ListThreadsRequest_Filter, options: { "verily.privacy.annotations.scrub": false } },
            { no: 2, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 3, name: "professional_account_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 4, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 5, name: "application_suite", kind: "enum", T: () => ["messaging.bff.api.ApplicationSuite", ApplicationSuite], options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<ListThreadsRequest>): ListThreadsRequest {
        const message = { participantRecordRefName: "", professionalAccountRefName: "", pageToken: "", applicationSuite: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListThreadsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListThreadsRequest): ListThreadsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* messaging.bff.api.ListThreadsRequest.Filter filter */ 1:
                    message.filter = ListThreadsRequest_Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* string participant_record_ref_name */ 2:
                    message.participantRecordRefName = reader.string();
                    break;
                case /* string professional_account_ref_name */ 3:
                    message.professionalAccountRefName = reader.string();
                    break;
                case /* string page_token */ 4:
                    message.pageToken = reader.string();
                    break;
                case /* messaging.bff.api.ApplicationSuite application_suite */ 5:
                    message.applicationSuite = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListThreadsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* messaging.bff.api.ListThreadsRequest.Filter filter = 1; */
        if (message.filter)
            ListThreadsRequest_Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string participant_record_ref_name = 2; */
        if (message.participantRecordRefName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.participantRecordRefName);
        /* string professional_account_ref_name = 3; */
        if (message.professionalAccountRefName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.professionalAccountRefName);
        /* string page_token = 4; */
        if (message.pageToken !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.pageToken);
        /* messaging.bff.api.ApplicationSuite application_suite = 5; */
        if (message.applicationSuite !== 0)
            writer.tag(5, WireType.Varint).int32(message.applicationSuite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.ListThreadsRequest
 */
export const ListThreadsRequest = new ListThreadsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListThreadsRequest_Filter$Type extends MessageType<ListThreadsRequest_Filter> {
    constructor() {
        super("messaging.bff.api.ListThreadsRequest.Filter", [
            { no: 2, name: "time_range", kind: "message", T: () => TimeRange }
        ]);
    }
    create(value?: PartialMessage<ListThreadsRequest_Filter>): ListThreadsRequest_Filter {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListThreadsRequest_Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListThreadsRequest_Filter): ListThreadsRequest_Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* messaging.bff.api.TimeRange time_range */ 2:
                    message.timeRange = TimeRange.internalBinaryRead(reader, reader.uint32(), options, message.timeRange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListThreadsRequest_Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* messaging.bff.api.TimeRange time_range = 2; */
        if (message.timeRange)
            TimeRange.internalBinaryWrite(message.timeRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.ListThreadsRequest.Filter
 */
export const ListThreadsRequest_Filter = new ListThreadsRequest_Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListThreadsResponse$Type extends MessageType<ListThreadsResponse> {
    constructor() {
        super("messaging.bff.api.ListThreadsResponse", [
            { no: 1, name: "thread_wraps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ThreadWrap },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListThreadsResponse>): ListThreadsResponse {
        const message = { threadWraps: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListThreadsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListThreadsResponse): ListThreadsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated messaging.bff.api.ThreadWrap thread_wraps */ 1:
                    message.threadWraps.push(ThreadWrap.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListThreadsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated messaging.bff.api.ThreadWrap thread_wraps = 1; */
        for (let i = 0; i < message.threadWraps.length; i++)
            ThreadWrap.internalBinaryWrite(message.threadWraps[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.ListThreadsResponse
 */
export const ListThreadsResponse = new ListThreadsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetThreadRequest$Type extends MessageType<GetThreadRequest> {
    constructor() {
        super("messaging.bff.api.GetThreadRequest", [
            { no: 1, name: "thread_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "verily.privacy.annotations.scrub": false } },
            { no: 2, name: "professional_account_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 3, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 4, name: "application_suite", kind: "enum", T: () => ["messaging.bff.api.ApplicationSuite", ApplicationSuite], options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<GetThreadRequest>): GetThreadRequest {
        const message = { threadRefName: "", professionalAccountRefName: "", participantRecordRefName: "", applicationSuite: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetThreadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetThreadRequest): GetThreadRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string thread_ref_name */ 1:
                    message.threadRefName = reader.string();
                    break;
                case /* string professional_account_ref_name */ 2:
                    message.professionalAccountRefName = reader.string();
                    break;
                case /* string participant_record_ref_name */ 3:
                    message.participantRecordRefName = reader.string();
                    break;
                case /* messaging.bff.api.ApplicationSuite application_suite */ 4:
                    message.applicationSuite = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetThreadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string thread_ref_name = 1; */
        if (message.threadRefName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.threadRefName);
        /* string professional_account_ref_name = 2; */
        if (message.professionalAccountRefName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.professionalAccountRefName);
        /* string participant_record_ref_name = 3; */
        if (message.participantRecordRefName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.participantRecordRefName);
        /* messaging.bff.api.ApplicationSuite application_suite = 4; */
        if (message.applicationSuite !== 0)
            writer.tag(4, WireType.Varint).int32(message.applicationSuite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.GetThreadRequest
 */
export const GetThreadRequest = new GetThreadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMessageAttachmentRequest$Type extends MessageType<GetMessageAttachmentRequest> {
    constructor() {
        super("messaging.bff.api.GetMessageAttachmentRequest", [
            { no: 1, name: "message_attachment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "verily.privacy.annotations.scrub": false } },
            { no: 2, name: "professional_account_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 3, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 4, name: "application_suite", kind: "enum", T: () => ["messaging.bff.api.ApplicationSuite", ApplicationSuite], options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<GetMessageAttachmentRequest>): GetMessageAttachmentRequest {
        const message = { messageAttachmentId: "", professionalAccountRefName: "", participantRecordRefName: "", applicationSuite: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetMessageAttachmentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMessageAttachmentRequest): GetMessageAttachmentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message_attachment_id */ 1:
                    message.messageAttachmentId = reader.string();
                    break;
                case /* string professional_account_ref_name */ 2:
                    message.professionalAccountRefName = reader.string();
                    break;
                case /* string participant_record_ref_name */ 3:
                    message.participantRecordRefName = reader.string();
                    break;
                case /* messaging.bff.api.ApplicationSuite application_suite */ 4:
                    message.applicationSuite = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMessageAttachmentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message_attachment_id = 1; */
        if (message.messageAttachmentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.messageAttachmentId);
        /* string professional_account_ref_name = 2; */
        if (message.professionalAccountRefName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.professionalAccountRefName);
        /* string participant_record_ref_name = 3; */
        if (message.participantRecordRefName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.participantRecordRefName);
        /* messaging.bff.api.ApplicationSuite application_suite = 4; */
        if (message.applicationSuite !== 0)
            writer.tag(4, WireType.Varint).int32(message.applicationSuite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.GetMessageAttachmentRequest
 */
export const GetMessageAttachmentRequest = new GetMessageAttachmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetParticipantRecordNameRequest$Type extends MessageType<GetParticipantRecordNameRequest> {
    constructor() {
        super("messaging.bff.api.GetParticipantRecordNameRequest", [
            { no: 1, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "verily.privacy.annotations.scrub": false } },
            { no: 2, name: "professional_account_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 3, name: "application_suite", kind: "enum", T: () => ["messaging.bff.api.ApplicationSuite", ApplicationSuite], options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<GetParticipantRecordNameRequest>): GetParticipantRecordNameRequest {
        const message = { participantRecordRefName: "", professionalAccountRefName: "", applicationSuite: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetParticipantRecordNameRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetParticipantRecordNameRequest): GetParticipantRecordNameRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string participant_record_ref_name */ 1:
                    message.participantRecordRefName = reader.string();
                    break;
                case /* string professional_account_ref_name */ 2:
                    message.professionalAccountRefName = reader.string();
                    break;
                case /* messaging.bff.api.ApplicationSuite application_suite */ 3:
                    message.applicationSuite = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetParticipantRecordNameRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string participant_record_ref_name = 1; */
        if (message.participantRecordRefName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.participantRecordRefName);
        /* string professional_account_ref_name = 2; */
        if (message.professionalAccountRefName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.professionalAccountRefName);
        /* messaging.bff.api.ApplicationSuite application_suite = 3; */
        if (message.applicationSuite !== 0)
            writer.tag(3, WireType.Varint).int32(message.applicationSuite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.GetParticipantRecordNameRequest
 */
export const GetParticipantRecordNameRequest = new GetParticipantRecordNameRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHyperlinkPreviewAllowListRequest$Type extends MessageType<GetHyperlinkPreviewAllowListRequest> {
    constructor() {
        super("messaging.bff.api.GetHyperlinkPreviewAllowListRequest", []);
    }
    create(value?: PartialMessage<GetHyperlinkPreviewAllowListRequest>): GetHyperlinkPreviewAllowListRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHyperlinkPreviewAllowListRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHyperlinkPreviewAllowListRequest): GetHyperlinkPreviewAllowListRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetHyperlinkPreviewAllowListRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.GetHyperlinkPreviewAllowListRequest
 */
export const GetHyperlinkPreviewAllowListRequest = new GetHyperlinkPreviewAllowListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHyperlinkPreviewAllowListResponse$Type extends MessageType<GetHyperlinkPreviewAllowListResponse> {
    constructor() {
        super("messaging.bff.api.GetHyperlinkPreviewAllowListResponse", [
            { no: 1, name: "hostnames", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetHyperlinkPreviewAllowListResponse>): GetHyperlinkPreviewAllowListResponse {
        const message = { hostnames: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHyperlinkPreviewAllowListResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHyperlinkPreviewAllowListResponse): GetHyperlinkPreviewAllowListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string hostnames */ 1:
                    message.hostnames.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHyperlinkPreviewAllowListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string hostnames = 1; */
        for (let i = 0; i < message.hostnames.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.hostnames[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.GetHyperlinkPreviewAllowListResponse
 */
export const GetHyperlinkPreviewAllowListResponse = new GetHyperlinkPreviewAllowListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetThreadAsReadRequest$Type extends MessageType<SetThreadAsReadRequest> {
    constructor() {
        super("messaging.bff.api.SetThreadAsReadRequest", [
            { no: 1, name: "thread_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "verily.privacy.annotations.scrub": false } },
            { no: 2, name: "professional_account_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 3, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } },
            { no: 4, name: "application_suite", kind: "enum", T: () => ["messaging.bff.api.ApplicationSuite", ApplicationSuite], options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<SetThreadAsReadRequest>): SetThreadAsReadRequest {
        const message = { threadRefName: "", professionalAccountRefName: "", participantRecordRefName: "", applicationSuite: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetThreadAsReadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetThreadAsReadRequest): SetThreadAsReadRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string thread_ref_name */ 1:
                    message.threadRefName = reader.string();
                    break;
                case /* string professional_account_ref_name */ 2:
                    message.professionalAccountRefName = reader.string();
                    break;
                case /* string participant_record_ref_name */ 3:
                    message.participantRecordRefName = reader.string();
                    break;
                case /* messaging.bff.api.ApplicationSuite application_suite */ 4:
                    message.applicationSuite = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetThreadAsReadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string thread_ref_name = 1; */
        if (message.threadRefName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.threadRefName);
        /* string professional_account_ref_name = 2; */
        if (message.professionalAccountRefName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.professionalAccountRefName);
        /* string participant_record_ref_name = 3; */
        if (message.participantRecordRefName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.participantRecordRefName);
        /* messaging.bff.api.ApplicationSuite application_suite = 4; */
        if (message.applicationSuite !== 0)
            writer.tag(4, WireType.Varint).int32(message.applicationSuite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.SetThreadAsReadRequest
 */
export const SetThreadAsReadRequest = new SetThreadAsReadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMessagingConfigurationRequest$Type extends MessageType<GetMessagingConfigurationRequest> {
    constructor() {
        super("messaging.bff.api.GetMessagingConfigurationRequest", [
            { no: 1, name: "application_suite", kind: "enum", T: () => ["messaging.bff.api.ApplicationSuite", ApplicationSuite], options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<GetMessagingConfigurationRequest>): GetMessagingConfigurationRequest {
        const message = { applicationSuite: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetMessagingConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMessagingConfigurationRequest): GetMessagingConfigurationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* messaging.bff.api.ApplicationSuite application_suite */ 1:
                    message.applicationSuite = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMessagingConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* messaging.bff.api.ApplicationSuite application_suite = 1; */
        if (message.applicationSuite !== 0)
            writer.tag(1, WireType.Varint).int32(message.applicationSuite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.GetMessagingConfigurationRequest
 */
export const GetMessagingConfigurationRequest = new GetMessagingConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessagingConfiguration$Type extends MessageType<MessagingConfiguration> {
    constructor() {
        super("messaging.bff.api.MessagingConfiguration", [
            { no: 1, name: "is_messaging_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "enabled_participant_records", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EnabledParticipantRecord }
        ]);
    }
    create(value?: PartialMessage<MessagingConfiguration>): MessagingConfiguration {
        const message = { isMessagingEnabled: false, enabledParticipantRecords: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessagingConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessagingConfiguration): MessagingConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_messaging_enabled */ 1:
                    message.isMessagingEnabled = reader.bool();
                    break;
                case /* repeated messaging.bff.api.EnabledParticipantRecord enabled_participant_records */ 2:
                    message.enabledParticipantRecords.push(EnabledParticipantRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessagingConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_messaging_enabled = 1; */
        if (message.isMessagingEnabled !== false)
            writer.tag(1, WireType.Varint).bool(message.isMessagingEnabled);
        /* repeated messaging.bff.api.EnabledParticipantRecord enabled_participant_records = 2; */
        for (let i = 0; i < message.enabledParticipantRecords.length; i++)
            EnabledParticipantRecord.internalBinaryWrite(message.enabledParticipantRecords[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.MessagingConfiguration
 */
export const MessagingConfiguration = new MessagingConfiguration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnabledParticipantRecord$Type extends MessageType<EnabledParticipantRecord> {
    constructor() {
        super("messaging.bff.api.EnabledParticipantRecord", [
            { no: 1, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EnabledParticipantRecord>): EnabledParticipantRecord {
        const message = { participantRecordRefName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EnabledParticipantRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnabledParticipantRecord): EnabledParticipantRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string participant_record_ref_name */ 1:
                    message.participantRecordRefName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnabledParticipantRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string participant_record_ref_name = 1; */
        if (message.participantRecordRefName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.participantRecordRefName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.EnabledParticipantRecord
 */
export const EnabledParticipantRecord = new EnabledParticipantRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHyperlinkPreviewResourceRequest$Type extends MessageType<GetHyperlinkPreviewResourceRequest> {
    constructor() {
        super("messaging.bff.api.GetHyperlinkPreviewResourceRequest", [
            { no: 1, name: "hyperlink", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<GetHyperlinkPreviewResourceRequest>): GetHyperlinkPreviewResourceRequest {
        const message = { hyperlink: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHyperlinkPreviewResourceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHyperlinkPreviewResourceRequest): GetHyperlinkPreviewResourceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string hyperlink */ 1:
                    message.hyperlink = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHyperlinkPreviewResourceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string hyperlink = 1; */
        if (message.hyperlink !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.hyperlink);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.GetHyperlinkPreviewResourceRequest
 */
export const GetHyperlinkPreviewResourceRequest = new GetHyperlinkPreviewResourceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HyperlinkPreviewResource$Type extends MessageType<HyperlinkPreviewResource> {
    constructor() {
        super("messaging.bff.api.HyperlinkPreviewResource", [
            { no: 1, name: "hyperlink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "hero_image", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HyperlinkPreviewResource>): HyperlinkPreviewResource {
        const message = { hyperlink: "", title: "", heroImage: new Uint8Array(0), mimeType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HyperlinkPreviewResource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HyperlinkPreviewResource): HyperlinkPreviewResource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string hyperlink */ 1:
                    message.hyperlink = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* bytes hero_image */ 3:
                    message.heroImage = reader.bytes();
                    break;
                case /* string mime_type */ 4:
                    message.mimeType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HyperlinkPreviewResource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string hyperlink = 1; */
        if (message.hyperlink !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.hyperlink);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* bytes hero_image = 3; */
        if (message.heroImage.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.heroImage);
        /* string mime_type = 4; */
        if (message.mimeType !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.mimeType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.HyperlinkPreviewResource
 */
export const HyperlinkPreviewResource = new HyperlinkPreviewResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ThreadWrap$Type extends MessageType<ThreadWrap> {
    constructor() {
        super("messaging.bff.api.ThreadWrap", [
            { no: 1, name: "thread", kind: "message", T: () => Thread },
            { no: 2, name: "organization", kind: "message", T: () => Organization }
        ]);
    }
    create(value?: PartialMessage<ThreadWrap>): ThreadWrap {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ThreadWrap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ThreadWrap): ThreadWrap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* messaging.bff.api.Thread thread */ 1:
                    message.thread = Thread.internalBinaryRead(reader, reader.uint32(), options, message.thread);
                    break;
                case /* messaging.bff.api.Organization organization */ 2:
                    message.organization = Organization.internalBinaryRead(reader, reader.uint32(), options, message.organization);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ThreadWrap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* messaging.bff.api.Thread thread = 1; */
        if (message.thread)
            Thread.internalBinaryWrite(message.thread, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* messaging.bff.api.Organization organization = 2; */
        if (message.organization)
            Organization.internalBinaryWrite(message.organization, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.ThreadWrap
 */
export const ThreadWrap = new ThreadWrap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Organization$Type extends MessageType<Organization> {
    constructor() {
        super("messaging.bff.api.Organization", [
            { no: 1, name: "organization_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Organization>): Organization {
        const message = { organizationRefName: "", displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Organization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Organization): Organization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_ref_name */ 1:
                    message.organizationRefName = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Organization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_ref_name = 1; */
        if (message.organizationRefName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationRefName);
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.Organization
 */
export const Organization = new Organization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Thread$Type extends MessageType<Thread> {
    constructor() {
        super("messaging.bff.api.Thread", [
            { no: 1, name: "thread_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "participant_record_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "latest_message", kind: "message", T: () => MessageWrap }
        ]);
    }
    create(value?: PartialMessage<Thread>): Thread {
        const message = { threadRefName: "", title: "", participantRecordId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Thread>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Thread): Thread {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string thread_ref_name */ 1:
                    message.threadRefName = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string participant_record_id */ 3:
                    message.participantRecordId = reader.string();
                    break;
                case /* messaging.bff.api.MessageWrap latest_message */ 4:
                    message.latestMessage = MessageWrap.internalBinaryRead(reader, reader.uint32(), options, message.latestMessage);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Thread, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string thread_ref_name = 1; */
        if (message.threadRefName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.threadRefName);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string participant_record_id = 3; */
        if (message.participantRecordId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.participantRecordId);
        /* messaging.bff.api.MessageWrap latest_message = 4; */
        if (message.latestMessage)
            MessageWrap.internalBinaryWrite(message.latestMessage, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.Thread
 */
export const Thread = new Thread$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageWrap$Type extends MessageType<MessageWrap> {
    constructor() {
        super("messaging.bff.api.MessageWrap", [
            { no: 1, name: "message", kind: "message", T: () => Message },
            { no: 2, name: "sender_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MessageWrap>): MessageWrap {
        const message = { senderName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageWrap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageWrap): MessageWrap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* messaging.bff.api.Message message */ 1:
                    message.message = Message.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                case /* string sender_name */ 2:
                    message.senderName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageWrap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* messaging.bff.api.Message message = 1; */
        if (message.message)
            Message.internalBinaryWrite(message.message, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string sender_name = 2; */
        if (message.senderName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.senderName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.MessageWrap
 */
export const MessageWrap = new MessageWrap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Message$Type extends MessageType<Message> {
    constructor() {
        super("messaging.bff.api.Message", [
            { no: 1, name: "message_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "thread_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "sent_time", kind: "message", T: () => Timestamp },
            { no: 4, name: "update_time", kind: "message", T: () => Timestamp },
            { no: 5, name: "sender_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "message_data", kind: "message", T: () => MessageData },
            { no: 7, name: "is_read", kind: "scalar", T: 8 /*ScalarType.BOOL*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 8, name: "is_read_by_participant", kind: "scalar", T: 8 /*ScalarType.BOOL*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<Message>): Message {
        const message = { messageRefName: "", threadId: "", senderRefName: "", isRead: false, isReadByParticipant: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Message>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Message): Message {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message_ref_name */ 1:
                    message.messageRefName = reader.string();
                    break;
                case /* string thread_id */ 2:
                    message.threadId = reader.string();
                    break;
                case /* google.protobuf.Timestamp sent_time */ 3:
                    message.sentTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sentTime);
                    break;
                case /* google.protobuf.Timestamp update_time */ 4:
                    message.updateTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updateTime);
                    break;
                case /* string sender_ref_name */ 5:
                    message.senderRefName = reader.string();
                    break;
                case /* messaging.bff.api.MessageData message_data */ 6:
                    message.messageData = MessageData.internalBinaryRead(reader, reader.uint32(), options, message.messageData);
                    break;
                case /* bool is_read */ 7:
                    message.isRead = reader.bool();
                    break;
                case /* bool is_read_by_participant */ 8:
                    message.isReadByParticipant = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Message, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message_ref_name = 1; */
        if (message.messageRefName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.messageRefName);
        /* string thread_id = 2; */
        if (message.threadId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.threadId);
        /* google.protobuf.Timestamp sent_time = 3; */
        if (message.sentTime)
            Timestamp.internalBinaryWrite(message.sentTime, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp update_time = 4; */
        if (message.updateTime)
            Timestamp.internalBinaryWrite(message.updateTime, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string sender_ref_name = 5; */
        if (message.senderRefName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.senderRefName);
        /* messaging.bff.api.MessageData message_data = 6; */
        if (message.messageData)
            MessageData.internalBinaryWrite(message.messageData, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bool is_read = 7; */
        if (message.isRead !== false)
            writer.tag(7, WireType.Varint).bool(message.isRead);
        /* bool is_read_by_participant = 8; */
        if (message.isReadByParticipant !== false)
            writer.tag(8, WireType.Varint).bool(message.isReadByParticipant);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.Message
 */
export const Message = new Message$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageData$Type extends MessageType<MessageData> {
    constructor() {
        super("messaging.bff.api.MessageData", [
            { no: 1, name: "message_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "attachment", kind: "message", T: () => MessageAttachment }
        ]);
    }
    create(value?: PartialMessage<MessageData>): MessageData {
        const message = { messageText: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageData): MessageData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message_text */ 1:
                    message.messageText = reader.string();
                    break;
                case /* messaging.bff.api.MessageAttachment attachment */ 2:
                    message.attachment = MessageAttachment.internalBinaryRead(reader, reader.uint32(), options, message.attachment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message_text = 1; */
        if (message.messageText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.messageText);
        /* messaging.bff.api.MessageAttachment attachment = 2; */
        if (message.attachment)
            MessageAttachment.internalBinaryWrite(message.attachment, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.MessageData
 */
export const MessageData = new MessageData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageAttachment$Type extends MessageType<MessageAttachment> {
    constructor() {
        super("messaging.bff.api.MessageAttachment", [
            { no: 1, name: "message_attachment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "blob", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 4, name: "metadata", kind: "message", T: () => MessageAttachment_Metadata, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<MessageAttachment>): MessageAttachment {
        const message = { messageAttachmentId: "", blob: new Uint8Array(0), mimeType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageAttachment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageAttachment): MessageAttachment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message_attachment_id */ 1:
                    message.messageAttachmentId = reader.string();
                    break;
                case /* bytes blob */ 2:
                    message.blob = reader.bytes();
                    break;
                case /* string mime_type */ 3:
                    message.mimeType = reader.string();
                    break;
                case /* messaging.bff.api.MessageAttachment.Metadata metadata */ 4:
                    message.metadata = MessageAttachment_Metadata.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageAttachment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message_attachment_id = 1; */
        if (message.messageAttachmentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.messageAttachmentId);
        /* bytes blob = 2; */
        if (message.blob.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.blob);
        /* string mime_type = 3; */
        if (message.mimeType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.mimeType);
        /* messaging.bff.api.MessageAttachment.Metadata metadata = 4; */
        if (message.metadata)
            MessageAttachment_Metadata.internalBinaryWrite(message.metadata, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.MessageAttachment
 */
export const MessageAttachment = new MessageAttachment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageAttachment_Metadata$Type extends MessageType<MessageAttachment_Metadata> {
    constructor() {
        super("messaging.bff.api.MessageAttachment.Metadata", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<MessageAttachment_Metadata>): MessageAttachment_Metadata {
        const message = { title: "", size: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageAttachment_Metadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageAttachment_Metadata): MessageAttachment_Metadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* uint32 size */ 2:
                    message.size = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageAttachment_Metadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* uint32 size = 2; */
        if (message.size !== 0)
            writer.tag(2, WireType.Varint).uint32(message.size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.MessageAttachment.Metadata
 */
export const MessageAttachment_Metadata = new MessageAttachment_Metadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeRange$Type extends MessageType<TimeRange> {
    constructor() {
        super("messaging.bff.api.TimeRange", [
            { no: 1, name: "lower_bound", kind: "message", T: () => Timestamp },
            { no: 2, name: "upper_bound", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TimeRange>): TimeRange {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TimeRange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeRange): TimeRange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp lower_bound */ 1:
                    message.lowerBound = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lowerBound);
                    break;
                case /* google.protobuf.Timestamp upper_bound */ 2:
                    message.upperBound = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.upperBound);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeRange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp lower_bound = 1; */
        if (message.lowerBound)
            Timestamp.internalBinaryWrite(message.lowerBound, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp upper_bound = 2; */
        if (message.upperBound)
            Timestamp.internalBinaryWrite(message.upperBound, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.TimeRange
 */
export const TimeRange = new TimeRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantRecord$Type extends MessageType<ParticipantRecord> {
    constructor() {
        super("messaging.bff.api.ParticipantRecord", [
            { no: 1, name: "participant_record_ref_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "participant_record_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ParticipantRecord>): ParticipantRecord {
        const message = { participantRecordRefName: "", participantRecordName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParticipantRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParticipantRecord): ParticipantRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string participant_record_ref_name */ 1:
                    message.participantRecordRefName = reader.string();
                    break;
                case /* string participant_record_name */ 2:
                    message.participantRecordName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParticipantRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string participant_record_ref_name = 1; */
        if (message.participantRecordRefName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.participantRecordRefName);
        /* string participant_record_name = 2; */
        if (message.participantRecordName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.participantRecordName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message messaging.bff.api.ParticipantRecord
 */
export const ParticipantRecord = new ParticipantRecord$Type();
/**
 * @generated ServiceType for protobuf service messaging.bff.api.MessagingBFFService
 */
export const MessagingBFFService = new ServiceType("messaging.bff.api.MessagingBFFService", [
    { name: "CreateMessage", options: {}, I: CreateMessageRequest, O: MessageWrap },
    { name: "ListMessages", options: {}, I: ListMessagesRequest, O: ListMessagesResponse },
    { name: "CreateThread", options: {}, I: CreateThreadRequest, O: ThreadWrap },
    { name: "ListThreads", options: {}, I: ListThreadsRequest, O: ListThreadsResponse },
    { name: "GetThread", options: {}, I: GetThreadRequest, O: ThreadWrap },
    { name: "GetMessageAttachment", options: {}, I: GetMessageAttachmentRequest, O: MessageAttachment },
    { name: "GetParticipantRecordName", options: {}, I: GetParticipantRecordNameRequest, O: ParticipantRecord },
    { name: "GetHyperlinkPreviewAllowList", options: {}, I: GetHyperlinkPreviewAllowListRequest, O: GetHyperlinkPreviewAllowListResponse },
    { name: "GetHyperlinkPreviewResource", options: {}, I: GetHyperlinkPreviewResourceRequest, O: HyperlinkPreviewResource },
    { name: "SetThreadAsRead", options: {}, I: SetThreadAsReadRequest, O: Empty },
    { name: "GetMessagingConfiguration", options: {}, I: GetMessagingConfigurationRequest, O: MessagingConfiguration }
]);
