// This method either calls the mock API or the real one, depending on environment variables
// Having different return types for rpcCallback and mockCallback prevents accidentally switching them
export default async function mockMessagingConfigurationRpc<Data, Response>(
  data: Data,
  rpcName: string,
  rpcCallback: (data: Data) => Promise<Response>,
  mockCallback: (data: Data) => Response | Promise<Response>
): Promise<Response> {
  // Prevent mocks from accidentally being used in production
  if (
    PHAF_INJECTED_VALUES.REACT_APP_MOCK_API &&
    !PHAF_INJECTED_VALUES.REACT_APP_RUN_BDD &&
    PHAF_INJECTED_VALUES.NODE_ENV !== 'production'
  ) {
    // This event lets Cypress BDD tests inspect the API call
    document.dispatchEvent(
      new CustomEvent('mock-api-call', {
        detail: {
          rpc: rpcName,
          data,
        },
      })
    );

    return mockCallback(data);
  } else {
    return rpcCallback(data);
  }
}
